import axios from "@axios";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { DATE_FORMAT, MONITOR_PAGE_LIMIT } from "src/config";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import usePermissions from "src/context/PermissionContext";
import { FinBoxResponse, PermissionTypes } from "src/types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  normalizeQueryKey,
  notify,
} from "src/utils/utils";
import { AvailableSources } from "../create-policy/types";
import {
  AddCustomMonitor,
  AddEndpointMonitor,
  AlertLogType,
  CustomMonitorData,
  EndpointMonitorData,
  GetEndpointMapping,
  GetEndpointPathMapping,
  UpdateEndpointMonitorType,
  UpdateCustomMonitor,
} from "./type";

export const useAddCustomMonitor = () => {
  return useMutation({
    mutationFn: ({
      name,
      description,
      status = "active",
      sourceName,
      channelType = "email",
      channelValue,
      monitorData,
    }: AddCustomMonitor) => {
      return axios.post<FinBoxResponse>("monitor", {
        name,
        description,
        status,
        sourceName,
        channelType,
        channelValue,
        monitorData,
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Monitor Added",
        text: "A custom monitor added successfully",
        type: "success",
      }),
  });
};

export const useAddEndpointMonitor = () => {
  return useMutation({
    mutationFn: ({
      name,
      description,
      sourceName,
      channelType,
      channelValue,
      status,
      monitorData,
    }: AddEndpointMonitor) => {
      console.log("call api");
      return axios.post<FinBoxResponse>("monitor", {
        name,
        description,
        sourceName,
        channelType,
        channelValue,
        status,
        monitorData,
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
};

export const useUpdateEndpointMonitor = () =>
  useMutation({
    mutationFn: ({
      name,
      monitorId,
      description,
      channelType,
      channelValue,
      status = 1,
      monitorType,
      monitorData,
    }: UpdateEndpointMonitorType) => {
      return axios.put<FinBoxResponse>("monitor", {
        name,
        monitorId,
        description,
        channelType,
        channelValue,
        status,
        monitorType,
        monitorData,
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });

export const useUpdateMonitor = () =>
  useMutation({
    mutationFn: ({
      name,
      monitorId,
      description,
      channelType,
      channelValue,
      status = 1,
      monitorType,
      monitorData,
    }: UpdateCustomMonitor) => {
      return axios.put<FinBoxResponse>("monitor", {
        name,
        monitorId,
        description,
        channelType,
        channelValue,
        status,
        monitorType,
        monitorData,
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });

export const useDeleteEndpointMonitor = () => {
  return useMutation({
    mutationFn: (monitorId: string) =>
      axios.delete<FinBoxResponse>(
        `monitor?monitorId=${monitorId}&monitorType=ENDPOINT_MONITOR`
      ),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
};
export const useDeleteCutomMonitor = () => {
  return useMutation({
    mutationFn: (monitorId: string) =>
      axios.delete<FinBoxResponse>(
        `monitor?monitorId=${monitorId}&monitorType=CUSTOMAPI_MONITOR`
      ),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
};

export const outputVariablesQuery = (
  endpointId: string,
  outputType: string,
  enabled: boolean
) =>
  queryOptions({
    queryKey: ["outputVariables", endpointId, outputType, enabled],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<
          {
            identifier: string;
            title: string;
            description: string;
          }[]
        >
      >(`monitor/endpoint/outputVariables?endpointId=${endpointId}`);
    },
    select: (data) => data.data.data,
    enabled: (!!endpointId || outputType === "output") && enabled,
    meta: {
      errorMessage: "This endpoint does not have any output variables",
    },
  });

export const outcomesVariablesQuery = (
  endpointId: string,
  outputType: string
) =>
  queryOptions({
    queryKey: ["outcomesVariable", endpointId],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<
          {
            identifier: string;
            title: string;
            description: string;
          }[]
        >
      >(`monitor/endpoint/outcomes?endpointId=${endpointId}`);
    },
    select: (data) => data.data.data,
    enabled: !!endpointId || outputType === "outcome",
    meta: {
      errorMessage: "This endpoint does not have any outcomes",
    },
  });

export const useGetOutputVar = (
  endpointId: string,
  outputType: string,
  enabled: boolean
) => useQuery(outputVariablesQuery(endpointId, outputType, enabled));
export const useGetOutcomesVar = (endpointId: string, outputType: string) =>
  useQuery(outcomesVariablesQuery(endpointId, outputType));

export const customMonitorsQuery = (args?: {
  state?: string;
  page?: string;
  name?: string;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["customMonitorsQuery", args]),
    queryFn: async () => {
      const params = {
        monitorType: "CUSTOMAPI_MONITOR",
        page: args?.page ?? "1",
        limit: MONITOR_PAGE_LIMIT,
        state: args?.state,
        monitorName: args?.name,
      };
      return axios.get<
        FinBoxResponse<{
          totalCount: number;
          monitors: CustomMonitorData[];
        }>
      >("monitor", {
        params,
      });
    },
    placeholderData: keepPreviousData,
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not fetch custom datasource monitors",
    },
  });

export const useGetCustomMonitors = (args: {
  state?: string;
  name?: string;
  page?: string;
}) => useQuery(customMonitorsQuery(args));

export const endpointMonitorsQuery = (args?: {
  state?: string;
  name?: string;
  page?: string;
  limit?: number;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["endpointMonitorsQuery", args]),
    queryFn: async () => {
      const params = {
        monitorType: "ENDPOINT_MONITOR",
        page: args?.page ?? 1,
        limit: args?.limit ?? MONITOR_PAGE_LIMIT,
        state: args?.state,
        monitorName: args?.name,
      };

      return axios.get<
        FinBoxResponse<{
          totalCount: number;
          monitors: EndpointMonitorData[];
        }>
      >("monitor", {
        params,
      });
    },
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not fetch endpoint monitors",
    },
  });

export const useGetEndpointMonitors = (args: {
  state?: string;
  name?: string;
  page?: string;
  limit?: number;
}) => useQuery(endpointMonitorsQuery(args));

export const monitorListQuery = (args?: {
  permissions: boolean;
  state?: string;
  name?: string;
  page?: string;
  limit?: number;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["monitorListQuery", args]),
    queryFn: async () => {
      const params = {
        monitorType: "",
        page: args?.page ?? 1,
        limit: args?.limit ?? MONITOR_PAGE_LIMIT,
        state: args?.state,
        monitorName: args?.name,
      };
      return axios.get<
        FinBoxResponse<{
          totalCount: number;
          monitors: (EndpointMonitorData | CustomMonitorData)[];
        }>
      >("overview/monitor", {
        params,
      });
    },
    enabled: args?.permissions,
    placeholderData: keepPreviousData,
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not get monitor list",
    },
  });

export const useGetAllMonitors = (args: {
  permissions: boolean;
  state?: string;
  name?: string;
  page?: string;
  limit?: number;
}) => {
  return useQuery(monitorListQuery(args));
};

export const useGetAlertLog = ({
  sourceName,
  states,
  name,
  page = "1",
  startDate,
  endDate,
  limit = MONITOR_PAGE_LIMIT,
}: {
  sourceName?: string;
  states?: string[];
  name?: string;
  page?: string;
  startDate: string | null;
  endDate: string | null;
  limit?: number;
}) =>
  useQuery({
    queryKey: [
      "getAlertLog",
      page,
      sourceName,
      states,
      name,
      startDate,
      endDate,
    ],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<{
          totalCount: number;
          alertHistory: AlertLogType[];
        }>
      >(`monitor/alertHistory`, {
        params: {
          page: parseInt(page),
          limit: limit,
          sourceName,
          states,
          startDate: getGQStartTime(startDate, DATE_FORMAT),
          endDate: getGQEndTime(endDate, DATE_FORMAT),
          monitorName: name,
        },
      });
    },
    placeholderData: keepPreviousData,
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Failed to get alert history",
    },
  });

export const useGetQueryEndpointMapping = (
  endpointId: string,
  enabled?: boolean
) =>
  useQuery({
    queryKey: ["enpointMapping", endpointId],
    queryFn: async () => {
      return axios.get<FinBoxResponse<GetEndpointMapping>>(
        `monitor/endpointpolicymapping?endpointId=${endpointId}`
      );
    },
    select: (data) => data.data.data,
    enabled: !!endpointId && enabled,
    meta: {
      errorMessage: "Failed to get the endpoint details",
    },
  });

export const useGetEnpointMonitorById = (
  endpointId: string,
  permissions?: boolean
) =>
  useQuery({
    queryKey: ["getEndpointByID", endpointId],
    queryFn: async () => {
      return axios.get<FinBoxResponse<EndpointMonitorData[]>>(
        `/monitor/endpoint?endpoint=${endpointId}`
      );
    },
    select: (data) => data.data.data,
    enabled: !!endpointId && permissions,
    meta: {
      errorMessage: "Failed to get the monitor list",
    },
  });

export const endpointPathQuery = () =>
  queryOptions({
    queryKey: ["endpointPaths"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<GetEndpointPathMapping>>(
        `monitor/endpointPaths`
      );
    },
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Failed to get the endpoint list",
    },
  });

export const useGetEndpointPaths = () => useQuery(endpointPathQuery());

export const datasourceListQuery = (
  args?: {
    pageNumber?: number;
    sourceName?: string;
    pageSize?: number;
  },
  permissions?: boolean
) =>
  queryOptions({
    queryKey: normalizeQueryKey(["datasourceList", args]),
    queryFn: async () => {
      const params = args;
      return axios.get<FinBoxResponse<AvailableSources>>(
        `/monitor/datasource/list`,
        {
          params,
        }
      );
    },
    enabled: permissions,
    select: (data) => data.data,
    placeholderData: keepPreviousData,
  });

export const useGetAvailableMonitorSourceList = (
  args: {
    pageNumber?: number;
    sourceName?: string;
    pageSize?: number;
  } = {}
) => {
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(
    PERMISSIONS_TYPE.monitorsCustom as PermissionTypes,
    ["edit", "create"]
  );
  return useQuery(datasourceListQuery(args, !!permissions));
};
