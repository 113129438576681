import { getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { useQuery } from "@tanstack/react-query";

export default function useKeywordsFromWorkflowKeywords(
  workflowId?: string,
  nodeId?: string,
  nodeName?: string
) {
  const workflowKeywords = useQuery(getWorkflowKeywordsQuery(workflowId));
  const keywords = {
    policies: {} as Record<string, string[]>,
    workflows: {} as Record<string, string[]>,
    sources: {} as Record<string, string[]>,
    functions: {} as Record<
      string,
      { syntax: string; returnType: string; description: string }
    >,
    inputs: [] as string[],
    args: [] as string[],
  };
  if (!workflowId || !nodeId) return keywords;
  if (!workflowKeywords.isSuccess) return keywords;

  keywords.functions = workflowKeywords.data.functions;
  keywords.inputs = workflowKeywords.data.inputs.list;
  keywords.args = workflowKeywords.data?.args?.list;

  const deps = workflowKeywords.data.dependents[nodeId];

  if (!deps) return keywords;

  const sourceList = deps.sources;
  // if(nodeName){
  //   sourceList.push(nodeName);
  // }
  if (sourceList) {
    sourceList.forEach((source) => {
      keywords.sources[source] =
        workflowKeywords.data.predictors.pluginSources[source]?.list ??
        workflowKeywords.data.predictors.customSources[source]?.list ??
        workflowKeywords.data.predictors.modelSet[source]?.list ??
        workflowKeywords.data.predictors.ruleSet[source]?.list ??
        workflowKeywords.data.predictors.lookupsSource[source]?.list ??
        workflowKeywords.data.predictors.inputSources[source]?.list;
    });
  }

  if (deps.policies)
    deps.policies.forEach((policy) => {
      keywords.policies[policy] =
        workflowKeywords.data.predictors.policies[policy]?.list;
    });

  if (deps.workflows)
    deps.workflows.forEach((workflow) => {
      keywords.workflows[workflow] =
        workflowKeywords.data.predictors.workflows[workflow]?.list;
    });
  return keywords;
}
