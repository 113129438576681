import classNames, { clsx } from "clsx";
import { ReactElement, ReactNode } from "react";
import Button from "@components/Button";
import Loader from "./Loader";

const TABLE_ROW_CLASSNAME =
  "[&>td]:relative border-b-neutral-50 leading-[50px] [&>td]:overflow-visible first:[&>td]:pl-6 [&>td]:font-b1 [&>td]:text-neutral-black last:[&>td]:pr-6 group cursor-pointer hover:bg-neutral-0 bg-white";

function PageButton({
  currentPage,
  page,
  onClick,
}: {
  currentPage: number;
  page: number;
  onClick: () => void;
}) {
  return (
    <span
      onClick={onClick}
      className={classNames(
        "rounded-md cursor-pointer hover:bg-[#ECECED] h-7 w-7 flex items-center justify-center font-b2-medium",
        currentPage === page
          ? "text-neutral-black bg-[#ECECED]"
          : "text-neutral-500"
      )}
    >
      {page}
    </span>
  );
}

export const Pagination = ({
  page,
  setPage,
  totalCount,
  pageLimit,
  pageLength,
  className,
  showIfEmpty,
}: {
  page?: number;
  setPage?: (p: number) => void;
  totalCount?: number;
  pageLimit?: number;
  pageLength?: number;
  className?: string;
  showIfEmpty?: boolean;
}) => {
  if (page && setPage && totalCount && pageLimit) {
    const totalPages = Math.ceil(totalCount / pageLimit);
    const pageSetter = (page: number) => () => setPage!(page);
    return showIfEmpty || totalPages > 1 ? (
      <div
        className={clsx(
          "px-6 bg-neutral-0 font-b1-medium text-neutral-black justify-between h-12 flex items-center border-t border-neutral-100",
          className
        )}
      >
        <span className="text-neutral-500">
          Showing {pageLength} of {totalCount}
        </span>
        <span className="flex gap-2">
          <Button
            className="mr-1"
            disabled={page === 1}
            variant="outline"
            onClick={pageSetter(Math.max(1, page - 1))}
          >
            Prev
          </Button>
          <PageButton onClick={pageSetter(1)} currentPage={page} page={1} />
          {page > 2 && (
            <>
              {page > 3 && <span className="text-neutral-black">...</span>}
              <PageButton
                onClick={pageSetter(Math.max(1, page - 1))}
                currentPage={page}
                page={page - 1}
              />
            </>
          )}
          {page !== 1 && page !== totalPages && (
            <PageButton onClick={() => {}} currentPage={page} page={page} />
          )}
          {page < totalPages - 1 && (
            <>
              <PageButton
                onClick={pageSetter(Math.min(totalPages, page + 1))}
                currentPage={page}
                page={page + 1}
              />
              {page < totalPages - 2 && (
                <span className="text-neutral-black">...</span>
              )}
            </>
          )}
          {totalPages > 1 && (
            <PageButton
              onClick={pageSetter(totalPages)}
              currentPage={page}
              page={totalPages}
            />
          )}
          <Button
            className="ml-1"
            variant="outline"
            onClick={pageSetter(Math.min(totalPages, page + 1))}
            disabled={page === totalPages}
          >
            Next
          </Button>
        </span>
      </div>
    ) : null;
  }
  return null;
};

type Props<T> = {
  data: T[];
  emptyText?: ReactElement;
  children: (row: T, defaultRowClassNames: string, index: number) => ReactNode;
  className?: string;
  headers?: Array<NonNullable<ReactNode>>;
  isLoading: boolean;
  page?: number;
  setPage?: (p: number) => void;
  totalCount?: number;
  pageLimit?: number;
  withTableEmptyText?: boolean;
};

export default function Table<T>({
  data,
  emptyText,
  children,
  className,
  isLoading,
  headers,
  page,
  setPage,
  totalCount,
  pageLimit,
  withTableEmptyText,
}: Props<T>) {
  if (!data || data?.length === 0) {
    return withTableEmptyText ? (
      <>
        <div
          className={classNames(
            "relative mt-0 w-full max-w-full overflow-auto border rounded-md border-neutral-100 sm:overflow-x-auto",
            className
          )}
        >
          <table className="relative min-w-full max-w-full overflow-visible">
            {headers && headers?.length > 0 && (
              <thead className="bg-neutral-0">
                <tr className="border-b border-neutral-100">
                  {headers.map((k, index) => (
                    <th
                      key={k.toString() + index}
                      scope="col"
                      className="py-2.5 first:pl-6 pr-6 text-left font-b1 text-neutral-500 bg-neutral-0"
                    >
                      {k}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className="divide-y overflow-visible bg-white">
              {isLoading ? (
                <div className="absolute left-0 right-0 bottom-0 top-0 flex h-full w-full items-center justify-center bg-neutral-25 bg-opacity-30">
                  <Loader />
                </div>
              ) : (
                emptyText
              )}
            </tbody>
          </table>
          <Pagination
            page={page}
            setPage={setPage}
            pageLimit={pageLimit}
            totalCount={totalCount}
            pageLength={data.length}
          />
        </div>
      </>
    ) : (
      <>{emptyText}</>
    );
  }

  return (
    <div
      className={classNames(
        "relative mt-0 w-full max-w-full overflow-auto border rounded-md border-neutral-100 sm:overflow-x-auto",
        className
      )}
    >
      <table className="relative min-w-full max-w-full  overflow-visible">
        {headers && headers?.length > 0 && (
          <thead className="bg-neutral-0">
            <tr className="border-b border-neutral-100">
              {headers.map((k, index) => (
                <th
                  key={k.toString() + index}
                  scope="col"
                  className="py-2.5 first:pl-6 pr-6 text-left font-b1 text-neutral-500 bg-neutral-0"
                >
                  {k}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="divide-y overflow-visible bg-white">
          {data.map((row, i) => children(row, TABLE_ROW_CLASSNAME, i))}
        </tbody>
      </table>
      <Pagination
        page={page}
        setPage={setPage}
        pageLimit={pageLimit}
        totalCount={totalCount}
        pageLength={data.length}
      />
      {isLoading && (
        <div className="absolute left-0 right-0 bottom-0 top-0 flex h-full w-full items-center justify-center bg-neutral-25 bg-opacity-30">
          <Loader />
        </div>
      )}
    </div>
  );
}

Table.TdWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames("min-h-[32px] flex items-center", className)}>
      {children}
    </div>
  );
};
