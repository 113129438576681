import { parse } from "csv-parse/browser/esm/sync";
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useRef,
  useState,
  useMemo,
} from "react";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import { notify } from "src/utils/utils";
import logger from "@utils/Logger";
import { useWorkflowContext } from "../../WorkflowContext";
import { transformResponseToColumnArray } from "../components/DecisionTable/utils";
import { DecisionTableRules } from "../components/ModelSet/types";

interface TableState {
  default: string;
  headers: {
    name: string;
  }[];
  rows: {
    column: string[];
    output: string;
  }[];
}

interface DecisionTableHookProps {
  matrixData: DecisionTableRules;
}

const useDecisionTable = ({ matrixData }: DecisionTableHookProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const lastColumnRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { isWorkflowEditable } = useWorkflowContext();

  const [currentlyEditing, setCurrentlyEditing] = useState<
    [number, number] | null
  >(null);
  const [tableData, setTableData] = useState<TableState>(() =>
    transformResponseToColumnArray(matrixData)
  );

  useOnClickOutside(ref, () => isWorkflowEditable && setCurrentlyEditing(null));

  const updateTableData = useCallback(
    (updater: (prevData: TableState) => TableState) => {
      if (isWorkflowEditable) {
        setTableData(updater);
      }
    },
    [isWorkflowEditable]
  );

  const addDecisionTableRow = useCallback(() => {
    updateTableData((prevData) => ({
      ...prevData,
      rows: [
        ...prevData.rows,
        { column: new Array(prevData.headers.length).fill(""), output: "" },
      ],
    }));
  }, [updateTableData]);

  const addDecisionTableColumn = useCallback(() => {
    updateTableData((prevData) => {
      const updatedHeaders = [...prevData.headers, { name: "" }];
      const updatedRows = prevData.rows.map((row) => ({
        ...row,
        column: [...row.column, ""],
      }));
      return { ...prevData, headers: updatedHeaders, rows: updatedRows };
    });

    setCurrentlyEditing([-1, tableData.headers.length]);

    setTimeout(() => {
      if (lastColumnRef.current && ref.current) {
        const container = ref.current;
        const lastColumn = lastColumnRef.current;
        const offset = lastColumn.offsetLeft + lastColumn.offsetWidth;
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        container.scrollLeft = Math.min(offset - 20, maxScrollLeft);
      }
    }, 0);
  }, [updateTableData, tableData?.headers?.length]);

  const deleteDecisionTableColumn = useCallback(
    (index: number) => {
      updateTableData((prevData) => ({
        ...prevData,
        headers: prevData.headers.filter((_, i) => i !== index),
        rows: prevData.rows.map((row) => ({
          ...row,
          column: row.column.filter((_, i) => i !== index),
        })),
      }));
    },
    [updateTableData]
  );

  const deleteDecisionTableRow = useCallback(
    (index: number) => {
      updateTableData((prevData) => ({
        ...prevData,
        rows: prevData.rows.filter((_, rowIndex) => rowIndex !== index),
      }));
    },
    [updateTableData]
  );

  const handleCellEdit = useCallback(
    (index: [number, number]) => {
      isWorkflowEditable && setCurrentlyEditing(index);
    },
    [isWorkflowEditable]
  );

  const handleCellDataChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentlyEditing?.length) {
        const { value } = event.target;
        const [rowIndex, colIndex] = currentlyEditing;
        updateTableData((prevData) => {
          const updatedRows = [...prevData.rows];
          updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            column: updatedRows[rowIndex].column.map((col, i) =>
              i === colIndex ? value : col
            ),
          };
          if (colIndex === prevData.headers.length) {
            updatedRows[rowIndex].output = value;
          }
          return { ...prevData, rows: updatedRows };
        });
      }
    },
    [currentlyEditing, updateTableData]
  );

  const handleColumnChange = useCallback(
    (name: string, colIndex: number) => {
      updateTableData((prevData) => ({
        ...prevData,
        headers: prevData.headers.map((header, i) =>
          i === colIndex ? { ...header, name } : header
        ),
      }));
    },
    [updateTableData]
  );

  const setDefaultDecisionTableValue = useCallback(
    (value: string) => {
      updateTableData((prevData) => ({
        ...prevData,
        default: value,
      }));
    },
    [updateTableData]
  );

  const importDecisionTable = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const result = reader.result as string;

        let rows: string[][] = [];
        try {
          rows = parse(result);
        } catch (err) {
          notify({ title: "Failed to import", text: "Invalid CSV file" });
          logger.error(err);
          return;
        }

        if (rows.length === 0) return;

        const headers = rows.shift()!;

        if (headers.pop()!.toLowerCase() !== "output") {
          notify({ title: "Invalid file", text: "output header is missing" });
          return;
        }

        const body: Array<{
          columns: { name: string; value: string }[];
          output: string;
        }> = [];
        for (let row of rows) {
          const items = row;
          const output = items.pop()!;

          const item = {
            columns: headers.map((header, index) => ({
              name: header,
              value:
                items[index].toUpperCase() === "TRUE"
                  ? "true"
                  : items[index].toUpperCase() === "FALSE"
                  ? "false"
                  : items[index],
            })),
            output,
          };
          body.push(item);
        }

        setTableData((prevTableData) => {
          // Create the updated headers
          const updatedHeaders = headers.map((name) => ({ name }));

          // Transform the response to rows
          const updatedRows = transformResponseToColumnArray({
            default: "",
            headers,
            rows: body,
          }).rows;

          // Return the new table data object with updated headers and rows
          return {
            ...prevTableData,
            headers: updatedHeaders,
            rows: updatedRows,
          };
        });

        if (fileInputRef.current) fileInputRef.current.value = "";
      },
      false
    );
    reader.readAsText(file, "Windows-1252");
  };

  const keyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!currentlyEditing || !matrixData) return;
    const [row, col] = currentlyEditing;
    const key = e.key;
    const headerCount = matrixData.headers.length;

    switch (key) {
      case "ArrowUp":
        // Output header is not editable. Handle that special case
        if (row === 0 && col === headerCount) break;

        setCurrentlyEditing([Math.max(-1, row - 1), col]);
        break;
      case "ArrowDown":
        setCurrentlyEditing([
          Math.min(matrixData.rows.length - 1, row + 1),
          col,
        ]);
        break;
    }
  };

  const memoizedValues = useMemo(
    () => ({
      tableData,
      addDecisionTableRow,
      deleteDecisionTableRow,
      addDecisionTableColumn,
      deleteDecisionTableColumn,
      handleColumnChange,
      handleCellDataChange,
      importDecisionTable,
      handleCellEdit,
      ref,
      lastColumnRef,
      fileInputRef,
      currentlyEditing,
      setCurrentlyEditing,
      isWorkflowEditable,
      keyPressHandler,
      setDefaultDecisionTableValue,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [
      tableData,
      addDecisionTableRow,
      deleteDecisionTableRow,
      addDecisionTableColumn,
      deleteDecisionTableColumn,
      handleColumnChange,
      handleCellDataChange,
      handleCellEdit,
      currentlyEditing,
      isWorkflowEditable,
      setDefaultDecisionTableValue,
    ]
  );

  return memoizedValues;
};

export default useDecisionTable;
