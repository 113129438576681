import { CSSProperties } from "react";
import { Connection, Edge, Node, XYPosition } from "reactflow";
import { ReactComponent as CheckIcon } from "@assets/icons/workflow/check-circle.svg";
import { ReactComponent as CantDecideIcon } from "@assets/icons/workflow/help-circle.svg";
import { ReactComponent as CustomIcon } from "@assets/icons/workflow/tool-01.svg";
import { ReactComponent as XIcon } from "@assets/icons/workflow/x-circle.svg";
import {
  BE_BRANCH_NODE_TYPE,
  BE_DECISION_TABLE_NODE_TYPE,
  BE_END_NODE_TYPE,
  BE_MODEL_NODE_TYPE,
  BE_MODEL_SET_NODE_TYPE,
  BE_POLICY_NODE_TYPE,
  BE_RULE_SET_NODE_TYPE,
  BE_SOURCE_NODE_TYPE,
  BE_WORKFLOW_NODE_TYPE,
  BRANCH_NODE_TYPE,
  DECISION_TABLE_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  OPERATORS,
  POLICY_NODE_TYPE,
  RULE_SET_NODE_TYPE,
  SOURCE_NODE_TYPE,
  WORKFLOW_NODE_TYPE,
} from "@screens/workflow/config";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { FINBOX_SOURCES } from "@config";
import {
  BranchEditNodeEvent,
  Operator,
  WorkflowAddConnectionEvent,
  WorkflowEventType,
} from "../types";

export type Condition = Array<string | [string, Operator, string]>;

export const getAddConnection = (params: Edge | Connection) => {
  if (!params.source || !params.target) {
    return null;
  }
  const e: WorkflowAddConnectionEvent = {
    from: params.source,
    fromHandle: params.sourceHandle || "",
    to: params.target,
    toHandle: params.targetHandle || "",
  };
  return {
    addConnection: e,
    type: "addConnection",
  } as WorkflowEventType;
};

export const getRemoveConnection = (edges: Edge[]) => {
  if (!edges.length) {
    return null;
  }
  // we should not do anything if the delete event comes with selecting the edge
  if (!edges[0].selected) {
    return null;
  }
  return {
    removeConnection: {
      from: edges[0]?.source,
      fromHandle: edges[0]?.sourceHandle,
      to: edges[0]?.target,
      toHandle: edges[0]?.targetHandle,
    },
    type: "removeConnection",
  } as WorkflowEventType;
};

export const getAddNewPolicyNode = (
  refID: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_POLICY_NODE_TYPE,
      refID: refID,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getAddNewWorkflowNode = (
  refID: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_WORKFLOW_NODE_TYPE,
      refID: refID,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getUpdatePolicyNode = (refID: string, nodeID: string) => {
  return {
    editNode: {
      nodeType: BE_POLICY_NODE_TYPE,
      refID: refID,
      nodeID: nodeID,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getUpdateWorkflowNode = (refID: string, nodeID: string) => {
  return {
    editNode: {
      nodeType: BE_WORKFLOW_NODE_TYPE,
      refID: refID,
      nodeID: nodeID,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getUpdateCustomEndNode = (refID: string, nodeID: string) => {
  return {
    editNode: {
      nodeType: BE_END_NODE_TYPE,
      refID: refID,
      nodeID: nodeID,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getAddNewBranchNode = (
  name: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_BRANCH_NODE_TYPE,
      refID: name,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getAddNewModelNode = (
  name: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_MODEL_NODE_TYPE,
      refID: name,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getAddNewMatrixNode = (
  name: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_DECISION_TABLE_NODE_TYPE,
      refID: name,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};
export const getAddNewModelSetNode = (
  name: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_MODEL_SET_NODE_TYPE,
      refID: name,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getUpdateModelNode = (
  name: string,
  nodeID: string,
  desc: string
) => {
  return {
    editNode: {
      nodeType: BE_MODEL_NODE_TYPE,
      refID: name,
      nodeID: nodeID,
      description: desc,
    },
    type: "editNode",
  } as WorkflowEventType;
};
export const getUpdateModelSetNode = (
  name: string,
  nodeID: string,
  desc: string
) => {
  return {
    editNode: {
      nodeType: BE_MODEL_SET_NODE_TYPE,
      refID: name,
      nodeID: nodeID,
      description: desc,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getUpdateRuleSetNode = (
  name: string,
  nodeID: string,
  desc: string
) => {
  return {
    editNode: {
      nodeType: BE_RULE_SET_NODE_TYPE,
      refID: name,
      nodeID: nodeID,
      description: desc,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getUpdateDecisionTableNode = (
  name: string,
  nodeID: string,
  desc: string
) => {
  return {
    editNode: {
      nodeType: BE_DECISION_TABLE_NODE_TYPE,
      refID: name,
      nodeID: nodeID,
      description: desc,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getAddNewRuleSetRule = (
  name: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_RULE_SET_NODE_TYPE,
      refID: name,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getAddNewSource = (
  name: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_SOURCE_NODE_TYPE,
      refID: name,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export const getUpdateBranchNode = (
  name: string,
  nodeID: string,
  desc: string,
  event?: BranchEditNodeEvent["event"]
) => {
  return {
    editNode: {
      nodeType: BE_BRANCH_NODE_TYPE,
      refID: name,
      nodeID: nodeID,
      description: desc,
      event: event,
    },
    type: "editNode",
  } as WorkflowEventType;
};

export const getRemoveNode = (node: Node) => {
  return {
    removeNode: {
      nodeID: node.id,
    },
    type: "removeNode",
  } as WorkflowEventType;
};

export const getAddNewEndNode = (
  refID: string,
  position: XYPosition,
  from?: string,
  fromHandle?: string | null
) => {
  return {
    addNode: {
      nodeType: BE_END_NODE_TYPE,
      refID: refID,
      metadata: position,
      from,
      fromHandle,
    },
    type: "addNode",
  } as WorkflowEventType;
};

export type Ternary = {
  condition: Array<string | [string, Operator, string]>;
  valueIfTrue: string | Ternary;
  valueIfFalse: string | Ternary;
};

export function extractTernary(expression: string): Ternary {
  let nestedCount = 0;
  let index = 0;
  let condition: Ternary["condition"] = [["", "<", ""]];
  let valueIfTrue: Ternary["valueIfTrue"] = "";
  let valueIfFalse: Ternary["valueIfFalse"] = "";

  while (index < expression.length) {
    const char = expression[index];

    if (char === "?" && nestedCount === 0) {
      condition = extractCondition(expression.substring(0, index).trim());
      const remainingExpression = expression.substring(index + 1);
      const separatorIndex = findTernarySeparatorIndex(remainingExpression);
      valueIfTrue = remainingExpression.substring(0, separatorIndex).trim();
      valueIfFalse = remainingExpression.substring(separatorIndex + 1).trim();
      break;
    }

    if (char === "(") {
      nestedCount++;
    } else if (char === ")") {
      nestedCount--;
    }

    index++;
  }

  if (valueIfTrue.startsWith("(") && valueIfTrue.endsWith(")"))
    valueIfTrue = extractTernary(
      valueIfTrue.substring(1, valueIfTrue.length - 1)
    );
  else if (
    (valueIfTrue.startsWith("'") && valueIfTrue.endsWith("'")) ||
    (valueIfTrue.startsWith('"') && valueIfTrue.endsWith('"'))
  )
    valueIfTrue = valueIfTrue.substring(1, valueIfTrue.length - 1);

  if (valueIfFalse.startsWith("(") && valueIfFalse.endsWith(")"))
    valueIfFalse = extractTernary(
      valueIfFalse.substring(1, valueIfFalse.length - 1)
    );
  else if (
    (valueIfFalse.startsWith("'") && valueIfFalse.endsWith("'")) ||
    (valueIfFalse.startsWith('"') && valueIfFalse.endsWith('"'))
  )
    valueIfFalse = valueIfFalse.substring(1, valueIfFalse.length - 1);

  return {
    condition,
    valueIfTrue,
    valueIfFalse,
  };
}

function findTernarySeparatorIndex(expression: string) {
  let nestedCount = 0;
  let index = 0;

  while (index < expression.length) {
    const char = expression[index];

    if (char === ":" && nestedCount === 0) {
      return index;
    }

    if (char === "(") {
      nestedCount++;
    } else if (char === ")") {
      nestedCount--;
    }
    index++;
  }
  return -1;
}

export function toTernary(
  input: Ternary,
  policies: { name: string; id: string }[] = []
): string {
  const { condition, valueIfTrue, valueIfFalse } = input;
  let currentExpression = `${conditionToString(condition)} `;

  if (typeof valueIfTrue === "string")
    if (valueIfTrue === "null") currentExpression += `? null `;
    else currentExpression += `? '${valueIfTrue}' `;
  else currentExpression += `? (${toTernary(valueIfTrue, policies)}) `;
  if (typeof valueIfFalse === "string")
    if (valueIfFalse === "null") currentExpression += `: null`;
    else currentExpression += `: '${valueIfFalse}'`;
  else currentExpression += `: (${toTernary(valueIfFalse, policies)})`;
  return currentExpression;
}

function validateOperator(op: string): op is Operator {
  return OPERATORS.includes(op as Operator);
}

function extractTokensFromCondition(
  str: string
): [string, Operator, string] | "" {
  const operatorRegex = /([<>!=]+)/;
  const matches = str.match(operatorRegex);

  if (matches && matches.length > 1 && validateOperator(matches[1])) {
    let [lhs, rhs] = str.split(matches[1]);
    return [lhs.trim(), matches[1], rhs.trim()];
  }
  return "";
}

export function extractCondition(str: string) {
  const operatorRegex = /(&&|\|\|)/g;
  const expressions = str.split(operatorRegex);
  const res = [];
  for (let i = 0; i < expressions.length; ++i) {
    if (expressions[i].length > 2)
      res.push(extractTokensFromCondition(expressions[i]));
    else if (res.length > 0) res.push(expressions[i]);
  }
  return res.length > 0 ? res : [["", "<", ""] as [string, Operator, string]];
}

export const validateKeyStringAndGetKeys = (
  key: string
): Array<keyof Ternary> => {
  const validKeys = ["condition", "valueIfTrue", "valueIfFalse"];
  const keys = key.split(".").filter((k) => k.length);
  if (keys.every((k) => validKeys.includes(k)))
    return keys as Array<keyof Ternary>;
  throw Error("Invalid key string: " + JSON.stringify(keys));
};

export const validateConditionObject = (
  condition: Array<string | [string, Operator, string]>
) => {
  if (condition.length === 0) return false;
  if (!Array.isArray(condition[0])) return false;
  let lastItem: "condition" | "operator" = "operator";
  for (let i = 0; i < condition.length; ++i) {
    if (lastItem === "operator") {
      if (
        !Array.isArray(condition[i]) ||
        !(condition[i][0] && condition[i][1] && condition[i][2])
      )
        return false;
      lastItem = "condition";
    } else {
      if (!condition[i] || Array.isArray(condition[i])) return false;
      lastItem = "operator";
    }
  }
  return lastItem !== "operator";
};

export const conditionToString = (
  condition?: Array<string | [string, Operator, string]>,
  validate: boolean = true
): string => {
  if (!condition || condition.length === 0) return "";
  if (validate && !validateConditionObject(condition))
    throw Error("Invalid rule");
  return condition.reduce<string>((p, c) => {
    if (Array.isArray(c)) return p + c.join(" ");
    else {
      return `${p} ${c} `;
    }
  }, "");
};

const excludeListForPreds = new Set([
  "<",
  ">",
  "<=",
  ">=",
  "==",
  "!=",
  "&&",
  "||",
]);

export function getPredsFromRuleObject(
  ruleObject: Ternary,
  predictorList: Record<string, string[]>,
  policies: Array<{ id: string; name: string; output: string[] }> = [],
  workflows: Array<{ id: string; name: string; output: string[] }> = []
) {
  const preds: string[] = [];
  const tokens = ruleObject.condition.flat();

  preds.push(...tokens.filter((k) => !excludeListForPreds.has(k)));
  if (typeof ruleObject.valueIfTrue !== "string")
    preds.push(
      ...getPredsFromRuleObject(
        ruleObject.valueIfTrue,
        predictorList,
        policies,
        workflows
      )
    );
  if (typeof ruleObject.valueIfFalse !== "string")
    preds.push(
      ...getPredsFromRuleObject(
        ruleObject.valueIfFalse,
        predictorList,
        policies,
        workflows
      )
    );

  const res: string[] = [];
  const uniqPreds = new Set(preds);
  uniqPreds.forEach((k) => {
    if (k.startsWith("policies[")) {
      const policyNameMatch = k.match(/policies\['(.*)']/);
      if (
        policyNameMatch?.[1] &&
        policies.find(
          (p) => p.name === policyNameMatch[1] || p.id === policyNameMatch[1]
        )
      ) {
        res.push(k);
      }
    } else if (k.startsWith("workflows[")) {
      const workflowNameMatch = k.match(/workflows\['(.*)']/);
      if (
        workflowNameMatch?.[1] &&
        workflows.find((wf) => wf.name === workflowNameMatch[1])
      ) {
        res.push(k);
      }
    } else {
      const split = k.split(".");
      const [source, pred] = split;
      if (split.length <= 1) return;
      if (predictorList[source]?.includes(pred)) {
        res.push(k);
      }
    }
  });
  return res;
}

export const getOutputs = (ruleObject: Ternary): string[] => {
  const res = [];
  if (typeof ruleObject.valueIfTrue === "string")
    res.push(ruleObject.valueIfTrue);
  else res.push(getOutputs(ruleObject.valueIfTrue));
  if (typeof ruleObject.valueIfFalse === "string")
    res.push(ruleObject.valueIfFalse);
  else res.push(getOutputs(ruleObject.valueIfFalse));
  return res.flat();
};

export const isValidConnection = (connection: Connection) => {
  return connection.source !== connection.target;
};

export function getIcon(label: string, styles?: CSSProperties) {
  switch (label.toLowerCase()) {
    case "approve":
    case "approved":
    case "pass":
    case "success":
      return (
        <CheckIcon className="stroke-success-500 fill-success-100 [&>path]:stroke-success-100 w-3.5 h-3.5" />
      );
    case "reject":
    case "rejected":
    case "fail":
    case "failure":
      return (
        <XIcon
          style={styles}
          className="stroke-error-500 fill-error-200 [&>path]:stroke-error-500 w-3.5 h-3.5"
        />
      );
    case "cant_decide":
      return (
        <CantDecideIcon
          style={styles}
          className="w-3.5 h-3.5 stroke-warning-500 fill-warning-100 [&>path]:stroke-warning-500 [&>path]:fill-warning-500"
        />
      );
    default:
      return (
        <CustomIcon style={styles} className="w-4 h-4 stroke-primary-900" />
      );
  }
}

export const getPolicyAndWorkflows = (expression: string) => {
  let policyPredictors: Record<string, Set<string>> = {};
  let workflowPredictors: Record<string, Set<string>> = {};
  const policyMatches = expression.matchAll(
    /policies\['(.*?)']\.+?(\.*[.A-Za-z0-9_]+)/g
  );

  Array.from(policyMatches)?.forEach((m) => {
    const [, policyId, pred] = m;
    if (policyPredictors[policyId]) {
      policyPredictors[policyId].add(pred);
    } else {
      policyPredictors[policyId] = new Set([pred]);
    }
  });

  const workflowMatches = expression.matchAll(
    /workflows\['(.*?)']\.+?(\.*[.A-Za-z0-9_]+)/g
  );
  Array.from(workflowMatches)?.forEach((m) => {
    const [, workflow, pred] = m;
    if (workflowPredictors[workflow]) {
      workflowPredictors[workflow].add(pred);
    } else {
      workflowPredictors[workflow] = new Set([pred]);
    }
  });
  return {
    policies: Object.entries(policyPredictors).reduce((prev, [key, value]) => {
      prev[key] = Array.from(value);
      return prev;
    }, {} as Record<string, string[]>),
    workflows: Object.entries(workflowPredictors).reduce(
      (prev, [key, value]) => {
        prev[key] = Array.from(value);
        return prev;
      },
      {} as Record<string, string[]>
    ),
  };
};

export function isValidName(name: string): [boolean, string] {
  if (!name) {
    return [false, "Name is empty"];
  }
  if (FINBOX_SOURCES.includes(name))
    return [false, "Cannot use an inbuilt source name"];
  if (/[^A-Za-z0-9_]/.test(name))
    return [false, "Name can only have letters, numbers and underscore"];
  return [true, ""];
}

export function getNewNodeName(
  type:
    | typeof WORKFLOW_NODE_TYPE
    | typeof POLICY_NODE_TYPE
    | typeof BRANCH_NODE_TYPE
    | typeof RULE_SET_NODE_TYPE
    | typeof SOURCE_NODE_TYPE
    | typeof MODEL_NODE_TYPE
    | typeof DECISION_TABLE_NODE_TYPE
    | typeof MODEL_SET_NODE_TYPE,
  currentNodes: Node[]
) {
  let nodeCount = currentNodes.filter((node) => node.type === type).length;
  const currentNodeNames = currentNodes.map((node) => node.data.label);

  let newName: string;
  do {
    newName = `${
      {
        [MODEL_NODE_TYPE]: "Model_Node_",
        [RULE_SET_NODE_TYPE]: "Ruleset_Node_",
        [BRANCH_NODE_TYPE]: "Branch_Node_",
        [DECISION_TABLE_NODE_TYPE]: "Decision_Table_",
        [POLICY_NODE_TYPE]: "Policy_Node_",
        [WORKFLOW_NODE_TYPE]: "Workflow_Node_",
        [SOURCE_NODE_TYPE]: "Source_Node_",
        [MODEL_SET_NODE_TYPE]: "Modelset_Node_",
      }[type]
    }${++nodeCount}`;
  } while (currentNodeNames.includes(newName));
  return newName;
}

export function getSuggestionList(
  keywords: ReturnType<typeof useKeywordsFromWorkflowKeywords>
) {
  const result = {
    sourceList: [] as string[],
    keywords: {} as {
      policies: Record<string, string[]>;
      workflows: Record<string, string[]>;
      predictorsList: Record<string, string[]>;
      input: string[];
      args: string[];
      functionsList: Record<
        string,
        { syntax: string; returnType: string; description: string }
      >;
    },
  };

  if (!keywords) return result;

  if (keywords.policies && Object.keys(keywords.policies).length > 0) {
    result.keywords.policies = keywords.policies;
    result.sourceList.push(
      ...Object.keys(keywords.policies).map((p) => `policies['${p}']`)
    );
  }

  if (keywords.workflows && Object.keys(keywords.workflows).length > 0) {
    result.keywords.workflows = keywords.workflows;
    result.sourceList.push(
      ...Object.keys(keywords.workflows).map((p) => `workflows['${p}']`)
    );
  }

  if (keywords.functions) {
    result.keywords.functionsList = keywords.functions;
  }

  if (Object.keys(keywords.sources ?? {}).length > 0) {
    result.keywords.predictorsList = keywords.sources;
    result.sourceList.push(...Object.keys(keywords.sources));
  }

  if (keywords.inputs?.length) {
    result.sourceList.push("input");
    result.keywords.input = keywords.inputs;
  }

  if (keywords.args?.length) {
    result.sourceList.push("args");
    result.keywords.args = keywords.args;
  }

  return result;
}

export const getUniqueItemName = (
  modelNames: string[],
  i: number,
  prefix: string
): string => {
  if (!modelNames.includes(`${prefix}${i}`)) {
    return `${prefix}${i}`;
  }
  return getUniqueItemName(modelNames, i + 1, prefix);
};
