export const transformDataWebhook = ({
  url,
  parameters,
  eventToggles,
  channelType,
}) => {
  const headers = parameters.reduce((acc, { key, value }) => {
    if (key && value) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const events = eventToggles.reduce((acc, { eventID, enabled }) => {
    if (enabled) {
      acc[eventID] = true;
    } else {
      acc[eventID] = false;
    }
    return acc;
  }, {});

  return {
    channelType: parseInt(channelType, 10),
    channelSpecificData: {
      url,
      headers,
    },
    events,
  };
};
