import { useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import Dropdown from "src/components/DropDown";
import ErrorText from "src/components/ErrorText";
import {
  classNames,
  ReplacedPattern,
  replacePatternWithIndex,
} from "src/utils/utils";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import useKeywordsPredictorsForConfigureModal, {
  getPredictorsType,
} from "../utils";
import AutocompleteDropdown from "./AutocompleteDropdown";

type Mapping = {
  childPredictors: string;
  parentPredictors: string;
  parentInputs: string;
  policyInputId: string;
  predictorsType: number;
};

type FormInput = {
  mapping: Mapping[];
};

interface SuggestionList {
  [key: string]: {
    [subKey: string]: string[];
  };
}

type FilterSectionProps = {
  errors: Partial<
    FieldErrorsImpl<{
      mapping: {
        childPredictors: string;
        parentPredictors: string;
        parentInputs: string;
        policyInputId: string;
      }[];
    }>
  >;
  clearErrors: UseFormClearErrors<FormInput>;
  index: number;
  control: Control<FormInput>;
  onDelete: (v: number) => void;
  childSourceList: { name: string; policyInputId: string }[];
  setValue: UseFormSetValue<FormInput>;
  parentWfId: string;
  wfNodeId: string;
  getValues: UseFormGetValues<FormInput>;
  isWorkflowEditable: boolean;
};

const FilterSection = ({
  errors,
  index,
  control,
  onDelete,
  childSourceList,
  setValue,
  getValues,
  parentWfId,
  wfNodeId,
  clearErrors,
  isWorkflowEditable,
}: FilterSectionProps) => {
  const [selectedParentPredictor, setSelectedParentPredictor] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const suggestionList: SuggestionList = useKeywordsPredictorsForConfigureModal(
    parentWfId,
    wfNodeId
  );

  const parentPredictors = useMemo(() => {
    const predictors: string[] = [];
    const inputsMap: { [key: string]: string[] } = {};
    const predictorsType: number[] = [];
    Object.entries(suggestionList).forEach(([key, value]) => {
      Object.entries(value).forEach(([subKey, subValue]) => {
        predictorsType.push(getPredictorsType(key));
        predictors.push(subKey);
        inputsMap[subKey] = subValue;
      });
    });

    return { predictors, inputsMap, predictorsType };
  }, [suggestionList]);

  return (
    <div id={`filter-section-${index}`} className="flex gap-2">
      <div className="flex flex-col">
        <Controller
          control={control}
          name={`mapping.${index}.childPredictors`}
          rules={{ required: { value: true, message: "* required" } }}
          render={({ field }) => (
            <Dropdown
              disabled={isWorkflowEditable}
              value={field.value}
              onChange={(e: any) => {
                setValue(`mapping.${index}.policyInputId`, e.policyInputId);
                field.onChange(e.name);
                clearErrors("mapping");
              }}
            >
              <Dropdown.Button
                className={classNames(
                  "!h-7 w-[142px] font-b2 disabled:cursor-not-allowed disabled:text-indigo-300 [&>img]:w-3 [&>img]:h-3 items-start justify-start"
                )}
              >
                {field.value ? (
                  <div className="grid text-neutral-black truncate w-[120px] justify-start">
                    {field.value}
                  </div>
                ) : (
                  "Select Variable"
                )}
              </Dropdown.Button>
              <Dropdown.Options className="min-w-[220px] !w-max !overflow-none">
                {childSourceList?.length > 0 ? (
                  childSourceList.map((p: any) => (
                    <Dropdown.Option value={p} key={p.policyInputId}>
                      {p.name}
                    </Dropdown.Option>
                  ))
                ) : (
                  <Dropdown.Option disabled value="No options">
                    No options
                  </Dropdown.Option>
                )}
              </Dropdown.Options>
            </Dropdown>
          )}
        />
        {errors?.mapping && errors?.mapping[index]?.childPredictors && (
          <ErrorText className="mt-1">
            {errors?.mapping[index]?.childPredictors?.message}
          </ErrorText>
        )}
      </div>
      =
      <div className="flex flex-col">
        <Controller
          control={control}
          name={`mapping.${index}.parentPredictors`}
          rules={{ required: { value: true, message: "* required" } }}
          render={({ field }) => (
            <AutocompleteDropdown
              placeholder="Select Source"
              isDisabled={isWorkflowEditable}
              classname="!w-[142px]"
              value={field.value}
              isSearch={false}
              onChange={(e) => {
                field.onChange(e);
                setSelectedParentPredictor(e as string);
                setValue(`mapping.${index}.parentInputs`, "");
                setValue(
                  `mapping.${index}.predictorsType`,
                  parentPredictors.predictorsType[
                    parentPredictors.predictors.indexOf(e)
                  ]
                );
              }}
              options={parentPredictors.predictors}
              optionsInfo={parentPredictors.predictorsType}
            />
          )}
        />
        {errors?.mapping && errors?.mapping[index]?.parentPredictors && (
          <ErrorText className="mt-1">
            {errors?.mapping[index]?.parentPredictors?.message}
          </ErrorText>
        )}
      </div>
      <div className="flex flex-col">
        <Controller
          control={control}
          name={`mapping.${index}.parentInputs`}
          rules={{ required: { value: true, message: "* required" } }}
          render={({ field }) => (
            <AutocompleteDropdown
              placeholder="Search & Select Predictor"
              classname="!w-[260px]"
              value={field.value}
              handleButtonClick={() => {
                setSelectedParentPredictor(
                  getValues(`mapping.${index}.parentPredictors`)
                );
              }}
              isSearch={true}
              onChange={(e) => {
                field.onChange(
                  replacePatternWithIndex(e, ReplacedPattern.INDEX2)
                );
              }}
              options={parentPredictors.inputsMap[selectedParentPredictor]}
              setSearchQuery={(e: string) => {
                setSearchQuery(e);
                field.onChange(e);
              }}
              searchQuery={searchQuery}
              isDisabled={
                getValues(`mapping.${index}.parentPredictors`).length === 0 ||
                isWorkflowEditable
              }
            />
          )}
        />
        {errors?.mapping && errors?.mapping[index]?.parentInputs && (
          <ErrorText className="mt-1">
            {errors?.mapping[index]?.parentInputs?.message}
          </ErrorText>
        )}
      </div>
      <button
        className={classNames(
          "text-neutral-500",
          errors?.mapping?.[index]?.parentInputs && "mt-[-18px]"
        )}
        disabled={isWorkflowEditable}
        type="button"
        onClick={() => onDelete(index)}
      >
        <TrashIcon
          className={classNames(
            "w-4 h-4 stroke-neutral-500",
            !isWorkflowEditable &&
              "[&:hover>path]:stroke-error-500 cursor-pointer ",
            isWorkflowEditable && "stroke-neutral-200 cursor-not-allowed"
          )}
        />
      </button>
    </div>
  );
};

export default FilterSection;
