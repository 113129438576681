import { containsSpecialChars, isEmptyObject } from "src/utils/utils";
import {
  InputParamDataEnum,
  InputParamOperation,
  WorkflowCustomInputType,
} from "./InputParameters.types";

type ValidationRuleProps<T> = {
  item: T;
  index?: number;
  extraArg: any;
};
type ValidationRule<T> = (props: ValidationRuleProps<T>) => string | null;

type ValidationSchema<T> = {
  [K in keyof T]?: ValidationRule<T>;
};

export const validateArray = <T>(
  data: T[],
  schema: ValidationSchema<T>,
  duplicateKeys: Array<keyof T> = [],
  extraArg?: any
): Array<Partial<Record<keyof T, string>>> => {
  const errors: Array<Partial<Record<keyof T, string>>> = Array(data?.length);
  const seenValues: Record<keyof T, Set<unknown>> = {} as Record<
    keyof T,
    Set<unknown>
  >;

  // Initialize sets for each key that requires duplicate checking
  duplicateKeys.forEach((key) => {
    seenValues[key] = new Set();
  });

  data.forEach((item, index) => {
    const itemErrors: Partial<Record<keyof T, string>> = {};

    // Validate according to schema
    for (const key in schema) {
      const rule = schema[key as keyof T];

      if (rule) {
        const error = rule({ item, index, extraArg });
        if (error) {
          itemErrors[key as keyof T] = error;
        }
      }
    }

    // Check for duplicate values
    duplicateKeys.forEach((key) => {
      const value = item[key];

      if (value !== undefined && seenValues[key].has(value)) {
        itemErrors[key] = `${String(key)} must be unique`;
      } else {
        seenValues[key].add(value);
      }
    });
    if (!isEmptyObject(itemErrors)) {
      errors[index] = itemErrors;
    } else {
      delete errors[index];
    }
  });

  return errors;
};

const dataTypeValidation = (
  props: ValidationRuleProps<WorkflowCustomInputType>
): string | null => {
  const { item, extraArg } = props;

  if (item.operation === InputParamOperation.DELETE) {
    return null;
  } else if (
    item.dataType === InputParamDataEnum.Object &&
    !item?.schema &&
    extraArg?.checkConfig
  ) {
    return "Please configure the object";
  } else {
    return null;
  }
};

const nameValidation = (
  props: ValidationRuleProps<WorkflowCustomInputType>
): string | null => {
  const { item } = props;
  if (item.operation === InputParamOperation.DELETE) {
    return null;
  } else if (!item?.name?.trim()?.length) {
    return "Name is required";
  } else if (containsSpecialChars(item?.name?.trim() ?? "")) {
    return "Name shouldn't have special characters except _ or start with number";
  } else {
    return null;
  }
};

const defaultInputValidation = (
  props: ValidationRuleProps<WorkflowCustomInputType>
): string | null => {
  const { item, extraArg } = props;
  if (item.operation === InputParamOperation.DELETE) {
    return null;
  } else if (
    item?.defaultInput &&
    item?.defaultInput.length > 0 &&
    extraArg?.isNullableEnabled &&
    item.dataType !== InputParamDataEnum.Object &&
    !item?.isNullable
  ) {
    return "Input should be nullable when there is a default value";
  } else if (
    item.dataType === InputParamDataEnum.Number &&
    item?.defaultInput?.length &&
    !/\d/.test(item?.defaultInput) &&
    item?.defaultInput !== "null"
  ) {
    return "Default value should be a number or null";
  } else if (
    item.dataType === InputParamDataEnum.Boolean &&
    item?.defaultInput?.length &&
    !["true", "false", "null"].includes(item?.defaultInput)
  ) {
    return "Default value should be one of true, false or null";
  } else {
    return null;
  }
};

export const validationSchema: ValidationSchema<WorkflowCustomInputType> = {
  name: nameValidation,
  dataType: dataTypeValidation,
  defaultInput: defaultInputValidation,
};
