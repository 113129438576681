import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Index_Regex } from "src/constants/appLevel";
import { useWorkflowContext } from "src/screens/workflow/WorkflowContext";
import {
  getCurrentSourcesQuery,
  getSourceListQuery,
  getSourceVariableMapping,
  getVariablesQuery,
  useAddSource,
  useUpdateSourceItem,
} from "@screens/workflow/studio/components/Source/queries";
import { SourceItem } from "@screens/workflow/studio/components/Source/types";
import Button from "@components/Button";
import Modal from "@components/Dialogue";
import QueryWrapper from "@components/QueryWrapper";
import Table from "@components/Table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { classNames, notify } from "@utils/utils";
import { PredictorSelect } from "./PredictorSelect";

export function ConfigureModalWhileAdd({ isUpdate }: { isUpdate?: boolean }) {
  const { isWorkflowEditable } = useWorkflowContext();
  const { workflowId, nodeId, datasourceId, oldDatasourceId } = useParams<{
    workflowId: string;
    nodeId: string;
    datasourceId: string;
    oldDatasourceId: string;
  }>();
  const variableList = useQuery(getVariablesQuery(workflowId!, datasourceId!));

  const queryClient = useQueryClient();

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [variables, setVariables] = useState<
    {
      dependsOn: number;
      value: string;
      dataSourceType: number;
      id: number;
    }[]
  >([]);

  const navigate = useNavigate();
  const addSourceMutation = useAddSource();
  const updateSourceMutation = useUpdateSourceItem();

  const close = () => navigate(`/workflow/${workflowId}`);

  const dataSourceDetails = queryClient
    .getQueryData(getSourceListQuery(workflowId!).queryKey)
    ?.data.data.find((i) => i.data_source_id === Number(datasourceId));

  const validate = () => {
    return (
      variableList.data?.data.data.variables.length === variables.length &&
      variables.every((v) => v.value)
    );
  };

  const save = () => {
    if (!validate()) return setShowErrors(true);

    const isIndexMissed = variables.some((header) =>
      Index_Regex.test(header.value)
    );
    if (isIndexMissed) {
      notify({ title: "Failed", text: "Please Specify index" });
      return;
    }

    setShowErrors(false);

    if (!isUpdate && !dataSourceDetails)
      return notify({
        title: "Invalid operation",
        text: "Trying to add an invalid datasource",
      });

    if (isUpdate)
      updateSourceMutation.mutate(
        {
          workflowId: workflowId!,
          datasourceId: Number(datasourceId!),
          nodeId: nodeId!,
          variables: [],
          dataSourceType: dataSourceDetails?.type!,
          oldDatasourceId: Number(oldDatasourceId!),
        },
        {
          onSuccess: () => {
            close();
            notify({
              title: "Added",
              text: "Added data source",
              type: "success",
            });
          },
        }
      );
    else
      addSourceMutation.mutate(
        {
          nodeId: nodeId!,
          workflowId: workflowId!,
          dataSourceType: dataSourceDetails?.type!,
          dataSourceId: Number(datasourceId!),
          variables,
        },
        {
          onSuccess: () => {
            close();
            notify({
              title: "Added",
              text: "Added data source",
              type: "success",
            });
          },
        }
      );
  };

  return (
    <Modal className="z-[1000]" open={true} onClose={() => close()}>
      <Modal.Panel size="lg">
        <Modal.Header>Configure Source</Modal.Header>
        <Modal.Body className="pb-4 px-6">
          <h1 className="font-b1 text-neutral-900">
            Map your API Input Variables to Policy Predictors
          </h1>
          <Table
            data={variableList.data?.data.data.variables || []}
            className="sm:overflow-x-visible w-full mt-4"
            emptyText={<p className="font-b2">No variables found</p>}
            isLoading={false}
            headers={["Variable Name", "Predictor"]}
          >
            {(row, defaultRowClassNames, index) => {
              return (
                <tr
                  className={classNames(
                    defaultRowClassNames,
                    "!cursor-default"
                  )}
                  key={row.variableID}
                >
                  <td
                    className="w-1/2 truncate py-3 pr-1"
                    style={{ overflow: "hidden" }}
                  >
                    <Table.TdWrapper className="h-[52px]">
                      {row.variableName}
                    </Table.TdWrapper>
                  </td>
                  <td className="w-1/2  min-w-[90px]">
                    <PredictorSelect
                      setValue={(value) => {
                        const prev = structuredClone(variables);
                        prev.splice(index, 1, value);
                        setVariables(prev);
                      }}
                      isWorkflowEditable={isWorkflowEditable}
                      variableID={
                        variableList.data?.data.data.variables[index]
                          .variableID ?? -1
                      }
                      showError={showErrors}
                    />
                  </td>
                </tr>
              );
            }}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" className="mr-3" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            disabled={addSourceMutation.isPending || !isWorkflowEditable}
            onClick={save}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}

export function ConfigureModalWhileUpdate() {
  const { isWorkflowEditable } = useWorkflowContext();
  const { workflowId, nodeId, datasourceId } = useParams<{
    workflowId: string;
    nodeId: string;
    datasourceId: string;
  }>();
  const variableList = useQuery(getVariablesQuery(workflowId!, datasourceId!));

  const queryClient = useQueryClient();

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [variables, setVariables] = useState<
    {
      dependsOn: number;
      value: string;
      dataSourceType: number;
      id: number;
    }[]
  >([]);

  const navigate = useNavigate();
  const updateSourceMutation = useUpdateSourceItem();

  const mappedVariables = useQuery(
    getSourceVariableMapping({
      workflowId: workflowId!,
      dataSourceId: Number(datasourceId!),
      enabled: true,
    })
  );

  const isFirstRender = useRef(true);

  if (
    isFirstRender.current &&
    !mappedVariables.isPending &&
    mappedVariables.isSuccess
  ) {
    isFirstRender.current = false;

    setVariables(
      mappedVariables.data.data.data.variables.map((item) => {
        return {
          id: item.variableID,
          value: item.value,
          dependsOn: item.dataSourceID,
          dataSourceType: item.dataSourceType,
        };
      })
    );
  }

  const close = () => navigate(`/workflow/${workflowId}`);

  const dataSourceDetails: SourceItem | undefined = queryClient
    .getQueryData(getCurrentSourcesQuery(workflowId!, nodeId!).queryKey)
    ?.data.data.find((i) => i.data_source_id === Number(datasourceId));

  const validate = () => {
    return (
      variableList.data?.data.data.variables.length === variables.length &&
      variables.every((v) => v.value)
    );
  };

  const save = () => {
    if (!validate()) return setShowErrors(true);
    setShowErrors(false);

    if (!dataSourceDetails)
      return notify({
        title: "Invalid operation",
        text: "Trying to edit an invalid datasource",
      });

    variables.forEach((item) => {
      item.value = item.value.replace(/(input\.)+/g, "input.");
    });

    const isIndexMissed = variables.some((header) =>
      Index_Regex.test(header.value)
    );
    if (isIndexMissed) {
      notify({ title: "Failed", text: "Please Specify index" });
      return;
    }

    updateSourceMutation.mutate(
      {
        nodeId: nodeId!,
        workflowId: workflowId!,
        dataSourceType: dataSourceDetails.type,
        datasourceId: Number(datasourceId!),
        oldDatasourceId: Number(datasourceId!),
        variables,
      },
      {
        onSuccess: () => {
          close();
          queryClient.invalidateQueries(
            getVariablesQuery(workflowId!, datasourceId!)
          );
          queryClient.invalidateQueries(
            getSourceVariableMapping({
              workflowId: workflowId!,
              dataSourceId: Number(datasourceId!),
              enabled: true,
            })
          );
          notify({
            title: "Updated",
            text: "Updated variable mapping",
            type: "success",
          });
        },
      }
    );
  };

  return (
    <Modal className="z-[1000]" open={true} onClose={() => close()}>
      <Modal.Panel size="lg">
        <Modal.Header>Configure Source</Modal.Header>
        <Modal.Body className="pb-4 px-6">
          <h1 className="font-b1 text-neutral-900">
            Map your API Input Variables to Policy Predictors
          </h1>
          <QueryWrapper query={mappedVariables}>
            {(data) => {
              return (
                <Table
                  data={variableList.data?.data.data.variables || []}
                  className="sm:overflow-x-visible w-full mt-4"
                  emptyText={<p className="font-b1">No variables found</p>}
                  isLoading={false}
                  headers={["Variable Name", "Predictor"]}
                >
                  {(row, defaultRowClassNames, index) => {
                    return (
                      <tr
                        className={classNames(
                          defaultRowClassNames,
                          "!cursor-default"
                        )}
                        key={row.variableID}
                      >
                        <td
                          className="w-1/2 truncate py-3 pr-1"
                          style={{ overflow: "hidden" }}
                        >
                          <Table.TdWrapper className="h-[52px]">
                            {row.variableName}
                          </Table.TdWrapper>
                        </td>
                        <td className="w-1/2 min-w-[90px]">
                          <PredictorSelect
                            showError={showErrors}
                            value={data?.data.data.variables[index]?.value}
                            setValue={(value) => {
                              const prev = structuredClone(variables);
                              prev.splice(index, 1, value);
                              setVariables(prev);
                            }}
                            isWorkflowEditable={isWorkflowEditable}
                            variableID={
                              variableList.data?.data.data.variables[index]
                                .variableID ?? -1
                            }
                          />
                        </td>
                      </tr>
                    );
                  }}
                </Table>
              );
            }}
          </QueryWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" className="mr-3" onClick={() => close()}>
            Cancel
          </Button>
          <Button
            disabled={updateSourceMutation.isPending || !isWorkflowEditable}
            onClick={save}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}
