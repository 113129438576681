import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import Shimmer from "src/components/Shimmer";
import { isEqual, notify } from "src/utils/utils";
import { WorkflowCustomInputType } from "../InputParameters.types";
import InputJsonTreeRender from "./InputJsonTreeRender";
import JsonCodeEditor from "./JsonCodeEditor";
import {
  useGetJsonInput,
  useSaveJsonInput,
  useTransformJson,
} from "./JsonInput.queries";
import { JsonNodeType, TransformBody } from "./JsonInput.types";
import { findPathIndexById, setPropertyAtPath } from "./JsonInput.utils";
import TransformLoader from "./TransformLoader";

export default function InputJsonModal({
  open,
  handleClose,
  inputInfo,
  isNullableEnabled,
  readOnly,
  mapInputId,
}: {
  open: boolean;
  handleClose: () => void;
  inputInfo: WorkflowCustomInputType;
  isNullableEnabled: boolean;
  readOnly: boolean;
  mapInputId: (inputId: string, jsonData: JsonNodeType | null) => void;
}) {
  const getJson = useGetJsonInput();
  const [jsonData, setJsonData] = useState<JsonNodeType | null>(null);
  const dataTransformed = useRef<boolean>(false);

  const [jsonInput, setJsonInput] = useState("");
  const [inputId, setInputId] = useState(inputInfo?.id);
  const { workflowId } = useParams();
  const transformJson = useTransformJson();
  const saveJsonApi = useSaveJsonInput();

  useEffect(() => {
    if (getJson?.data?.data?.data) {
      setJsonData(JSON.parse(JSON.stringify(getJson.data.data.data)));
      if (getJson?.data?.data?.data.schema) {
        setJsonInput(JSON.stringify(getJson?.data?.data?.data.schema, null, 2));
      }
    }
  }, [getJson?.data?.data?.data]);

  useEffect(() => {
    if (!inputInfo?.id?.includes("dummy")) {
      getJson.mutate(inputInfo.id);
    }
  }, [inputInfo?.id]);

  useEffect(() => {
    if (transformJson?.data?.data?.data) {
      setJsonData(transformJson.data.data.data);
      setInputId(transformJson.data.data.data.id);
      dataTransformed.current = true;
    }
  }, [transformJson.data]);

  useEffect(() => {
    if (saveJsonApi?.data?.data?.data) {
      mapInputId(saveJsonApi.data.data.data, jsonData);
      setJsonData(null);
      setJsonInput("");
    }
  }, [saveJsonApi.data]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const elementId = e?.target?.id;
    if (elementId?.includes("nullable")) {
      const id = elementId.split("_")[0];
      const isChecked = e.target.checked;
      setJsonData((prev) => {
        if (prev) {
          let newData = { ...prev };
          const nodePath = findPathIndexById(newData, id);
          if (nodePath) {
            newData = setPropertyAtPath(
              newData,
              nodePath,
              "isNullable",
              isChecked
            );
          }
          return newData;
        }
        return prev;
      });
    } else if (elementId?.includes("defaultInput")) {
      const id = elementId.split("_")[0];
      const value = e.target.value;
      setJsonData((prev) => {
        if (prev) {
          let newData = { ...prev };
          const nodePath = findPathIndexById(newData, id);
          if (nodePath) {
            newData = setPropertyAtPath(
              newData,
              nodePath,
              "defaultInput",
              value
            );
          }
          return newData;
        }
        return prev;
      });
    }
  };

  const transformJsonData = () => {
    if (jsonInput) {
      try {
        const body: TransformBody = {
          data: JSON.parse(jsonInput),
          name: inputInfo.name,
        };
        if (!inputInfo?.id?.includes("dummy")) {
          body.id = inputInfo.id;
        }

        transformJson.mutate(body);
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred.";
        notify({
          title: "Error",
          text: errorMessage,
        });
      }
    }
  };

  const saveJsonData = () => {
    if (jsonData && workflowId) {
      saveJsonApi.mutate({
        workflowID: workflowId,
        policyInputID: inputId,
        policyInputObj: jsonData,
      });
    }
  };

  const onClose = () => {
    setJsonData(null);
    setJsonInput("");
    handleClose();
  };

  if (getJson.isPending) {
    return <Shimmer w="400px" h="20px" />;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Panel size="lg" className="w-[850px] h-[636px]">
        <Modal.Header className="z-10">Configure JSON</Modal.Header>
        <Modal.Body className="!h-[600px] !max-h-full !p-0 overflow-hidden">
          <div className="grid grid-cols-2 h-full">
            <JsonCodeEditor
              setJsonInput={setJsonInput}
              jsonInput={jsonInput}
              transformJsonData={transformJsonData}
              transformDisabled={
                transformJson.isPending ||
                JSON.stringify(
                  getJson?.data?.data?.data?.schema ?? "",
                  null,
                  2
                ) === jsonInput
              }
            />
            <div onChange={handleChange}>
              <InputJsonTreeRender
                data={jsonData}
                isNullableEnabled={isNullableEnabled}
                readOnly={readOnly}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-neutral-0 !py-0 !h-11 justify-end items-center border-t -bottom-6 border-neutral-100 z-10">
          <div className="flex gap-4 items-center">
            <Button
              variant="primary"
              className="ml-auto w-full justify-center"
              onClick={saveJsonData}
              disabled={
                saveJsonApi.isPending ||
                (isEqual(getJson?.data?.data?.data ?? null, jsonData) &&
                  !dataTransformed.current)
              }
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
        {(transformJson.isPending || saveJsonApi.isPending) && (
          <TransformLoader
            showText={saveJsonApi.isPending ? "Saving JSON" : "Parsing data"}
            open={transformJson.isPending || saveJsonApi.isPending}
          />
        )}
      </Modal.Panel>
    </Modal>
  );
}
