import Button from "src/components/Button";
import { NO_WEBHOOK } from "src/constants/imageConstants";

const NoWebhookEmptyState = ({
  handleOpenModal,
}: {
  handleOpenModal: () => void;
}) => {
  return (
    <div className="flex flex-col items-center m-auto mt-20">
      <img src={NO_WEBHOOK} alt="no_webhook" />
      <p className="font-b1-medium text-neutral-black mt-6">
        No Webhook Set Up Yet
      </p>
      <p className="font-b2 text-neutral-500 mt-3">
        It looks like you haven’t added any webhooks. You can set up a webhook
        to receive updates on various events.
      </p>
      <Button variant="primary" className="mt-3" onClick={handleOpenModal}>
        Add Webhook
      </Button>
    </div>
  );
};
export default NoWebhookEmptyState;
