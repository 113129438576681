// Enums for Login Actions
export enum LoginActions {
  SSO = "login_sso",
  SSO_SIGNIN = "login_sso_signin",
  SSO_REQUEST_ACCESS = "login_sso_request_access",
  SIGNIN = "login_signin",
  RESET_PASSWORD = "login_reset_password",
  FORGOT_PASSWORD = "login_forgot_password",
  REQUEST_ACCESS = "login_request_access",
}

// Enums for Toolbar Actions
export enum ToolbarActions {
  HOME = "toolbar_home",
  HELP_AND_DOCS = "toolbar_help_and_docs",
  NOTIFICATIONS = "toolbar_notifications",
  NOTIFICATIONS_MARK_AS_READ = "toolbar_notifications_mark_as_read",
  NOTIFICATIONS_ONLY_SHOW_UNREAD = "toolbar_notifications_only_show_unread", // Corrected 'shoow' to 'show'
  NOTIFICATIONS_ALL_FILTER = "toolbar_notifications_all_filter",
  NOTIFICATIONS_POLICY_FILTER = "toolbar_notifications_policy_filter",
  NOTIFICATIONS_REPORTS_FILTER = "toolbar_notifications_reports_filter",
  NOTIFICATIONS_ALERTS_FILTER = "toolbar_notifications_alerts_filter",
  PROFILE = "toolbar_profile",
  PROFILE_SETTINGS = "toolbar_profile_settings",
  PROFILE_SIGNOUT = "toolbar_profile_signout",
}

// Enums for Sidebar Actions
export enum SidebarActions {
  OVERVIEW = "sidebar_overview",
  POLICIES = "sidebar_policies",
  ENDPOINTS = "sidebar_endpoints",
  DATASOURCES = "sidebar_datasources",
  REPORTS = "sidebar_reports",
  ANALYTICS = "sidebar_analytics",
  MONITORS = "sidebar_monitors",
  SETTINGS = "sidebar_settings",
}

// Enums for Overview Actions
export enum OverviewActions {
  CLOSE_EXPLORE_SENTINEL = "overview_close_explore_sentinel",
  ONBOARDING_ACTION = "overview_onboarding_action",
  ONBOARDING_VIDEO = "overview_onboarding_video",
  INVITE_MEMBERS_VIDEO = "overview_invite_members_video",
  INVITE_MEMBERS = "overview_invite_members",
  INVITE_MEMBERS_SKIP = "overview_invite_members_skip",
  CREATE_YOUR_FIRST_POLICY_VIDEO = "overview_create_your_first_policy_video",
  CREATE_YOUR_FIRST_POLICY = "overview_create_your_first_policy",
  CREATE_YOUR_FIRST_POLICY_SKIP = "overview_create_your_first_policy_skip",
  PUBLISH_A_POLICY_VIDEO = "overview_publish_a_policy_video",
  PUBLISH_A_POLICY = "overview_publish_a_policy",
  PUBLISH_A_POLICY_SKIP = "overview_publish_a_policy_skip",
  VIEW_OUTCOMES_AND_DETAILS = "overview_view_outcomes_and_outcomes_details",
  VIEW_OUTCOMES_AND_DETAILS_SKIP = "overview_view_outcomes_and_outcomes_details_skip",
  CREATE_ANALYTICS_TEMPLATE_VIDEO = "overview_create_analytics_template_video",
  CREATE_ANALYTICS_TEMPLATE = "overview_create_analytics_template",
  CREATE_ANALYTICS_TEMPLATE_SKIP = "overview_create_analytics_template_skip",
  CREATE_MONITOR_VIDEO = "overview_create_monitor_video",
  CREATE_MONITOR = "overview_create_monitor",
  CREATE_MONITOR_SKIP = "overview_create_monitor_skip",
  PROGRAM = "overview_program",
  TIME_PERIOD = "overview_time_period",
  DECISION_DROPDOWN = "overview_decision_dropdown",
}

// Enums for Policies Actions
export enum PoliciesActions {
  OVERVIEW = "policies_overview",
  FILTER = "policies_filter",
  PROGRAM = "policies_program",
  POLICY_FILTER = "policies_policy_filter",
  WORKFLOW_FILTER = "policies_workflow_filter",
  ADD_POLICY = "policies_add_policy",
  CREATE_BUCKET = "policies_create_bucket",
  LIST_OPTIONS_VIEW_BUCKET = "policies_list_options_view_bucket",
  LIST_OPTIONS_DUPLICATE_BUCKET = "policies_list_options_duplicate_bucket",
  LIST_OPTIONS_DUPLICATE_BUCKET_CREATE = "policies_list_options_duplicate_bucket_create",
}

// Enums for Policies Bucket Actions
export enum PoliciesBucketActions {
  ADD_VERSION = "policies_bucket_add_version",
  POLICY_ANALYTICS = "policies_bucket_policy_analytics",
  DOWNLOAD_WORKFLOW = "policies_bucket_download_workflow",
  DUPLICATE_VERSION = "policies_bucket_duplicate_version",
  DUPLICATE_VERSION_CREATE = "policies_bucket_duplicate_version_create",
  EDIT_WORKFLOW = "policies_bucket_edit_workflow",
  ARCHIVE = "policies_bucket_archive",
}

// Enums for Policy Bucket Actions
export enum PolicyBucketActions {
  FILTER = "policy_bucket_filter",
}

// Enums for Policy Analytics Actions
export enum PolicyAnalyticsActions {
  POLICY_FILTER = "policy_analytics_policy_filter",
  DATE_RANGE = "policy_analytics_date_range",
  DOWNLOAD_REPORT = "policy_analytics_download_report",
  DOWNLOAD_PREDICTORS = "policy_analytics_download_predictors",
}

// Enums for Policy Studio Actions
export enum PolicyStudioActions {
  TOOLBAR_DOWNLOAD = "policy_studio_toolbar_download",
  TOOLBAR_STATUS = "policy_studio_toolbar_status",
  ADD_BLOCK = "policy_studio_add_block",
  SOURCE_BLOCK = "policy_studio_source_block",
  BRANCH = "policy_studio_branch",
  POLICY = "policy_studio_policy",
  WORKFLOW = "policy_studio_workflow",
  RULESET = "policy_studio_ruleset",
  MODELSET = "policy_studio_modelset",
  APPROVED = "policy_studio_approved",
  CANT_DECIDE = "policy_studio_cant_decide",
  REJECTED = "policy_studio_rejected",
  CUSTOM = "policy_studio_custom",
  BLOCK_EXPORT = "policy_studio_block_export",
  SIDEBAR_ADD_BLOCK = "policy_studio_sidebar_add_block",
  SIDEBAR_NAVIGATOR = "policy_studio_sidebar_navigator",
  SIDEBAR_INPUT_PARAMETERS_MARK_ALL_AS_NULLABLE = "policy_studio_sidebar_input_parameters_mark_all_as_nullable",
  SIDEBAR_INPUT_PARAMETERS_EXPORT = "policy_studio_sidebar_input_parameters_export",
  SIDEBAR_INPUT_PARAMETERS_ADD_PARAMETER = "policy_studio_sidebar_input_parameters_add_parameter",
  SIDEBAR_INPUT_PARAMETERS_UPLOAD = "policy_studio_sidebar_input_parameters_upload",
  SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAMPLECSV = "policy_studio_sidebar_input_parameters_download_samplecsv",
  SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAMPLEJSON = "policy_studio_sidebar_input_parameters_download_samplejson",
  SIDEBAR_INPUT_PARAMETERS_DOWNLOAD_SAVE = "policy_studio_sidebar_input_parameters_download_save",
  SIDEBAR_OUTPUT_PARAMETERS_TYPE = "policy_studio_sidebar_output_parameters_type",
  SIDEBAR_OUTPUT_ADD = "policy_studio_sidebar_output_add",
  SIDEBAR_OUTPUT_SAVE = "policy_studio_sidebar_output_save",
  SIDEBAR_OUTPUT_DELETE = "policy_studio_sidebar_output_delete",
  SIDEBAR_TEST_WORKFLOW_BLOCK_SELECT = "policy_studio_sidebar_test_workflow_block_select",
  SIDEBAR_TEST_WORKFLOW_RUN_TEST = "policy_studio_sidebar_test_workflow_run_test",
  SIDEBAR_SIMULATION_TYPE = "policy_studio_sidebar_simulation_type",
  SIDEBAR_SIMULATION_HISTORICAL_RUN_SIMULATION = "policy_studio_sidebar_simulation_historical_run_simulation",
  SIDEBAR_SIMULATION_APPLICATION_RUN_SIMULATION = "policy_studio_sidebar_simulation_application_run_simulation",
  SIDEBAR_SIMULATION_CUSTOM_UPLOAD = "policy_studio_sidebar_simulation_custom_upload",
  SIDEBAR_SIMULATION_CUSTOM_DATA_TEMPLATE_DOWNLOAD = "policy_studio_sidebar_simulation_custom_data_template_download",
  SIDEBAR_SIMULATION_CUSTOM_RUN_SIMULATION = "policy_studio_sidebar_simulation_custom_run_simulation",
  SIDEBAR_SETTINGS_ENABLE_NULLABLE_INPUTS = "policy_studio_sidebar_settings_enable_nullable_inputs",
  SIDEBAR_SETTINGS_ENABLE_SOURCE_ERROR_HANDLING = "policy_studio_sidebar_settings_enable_source_error_handling",
  OPTIONS_ZOOM = "policy_studio_options_zoom",
  OPTIONS_MAX_ZOOM = "policy_studio_options_maxzoom",
  OPTIONS_DOWNLOAD = "policy_studio_options_download",
  AUTO_ARRANGE = "policy_studio_auto_arrange",
  WORKFLOW_INFO = "policy_studio_toolbar_workflow_info",
  DOWNLOAD_ATTACH_DOC = "policy_studio_toolbar_workflow_info_download_doc",
  CHANGELOG = "policy_studio_toolbar_changelogs",
  CHANGELOG_ADD_VERSION = "policy_studio_toolbar_changelogs_add_version",
  CHANGELOG_VIEW = "policy_studio_toolbar_changelogs_view_changelog",
}

// Enums for Endpoints Actions
export enum EndpointsActions {
  NEW = "endpoints_new",
  NEW_CREATE = "endpoints_new_create",
  PROGRAM_TOGGLE = "endpoint_program_toggle",
  SELECT_ENDPOINT = "endpoints_select_endpoint",
  SELECT_POLICY = "endpoints_select_policy",
  GENERATE_REPORT = "endpoints_generate_report",
  GENERATE_REPORT_SUBMIT = "endpoints_generate_report_generate",
  SHOW_CURL = "endpoints_show_curl",
  SHOW_CURL_COPY = "endpoints_show_curl_copy",
  ROLLBACK = "endpoints_rollback",
  ROLLBACK_CLOSE = "endpoints_rollback_close",
  ROLLBACK_CONFIRM = "endpoints_rollback_confirm",
  DELETE = "endpoints_delete",
  PUBLISH_POLICY_LEARN_MORE = "endpoints_publish_policy_learn_more",
  PUBLISH_POLICY = "endpoints_publish_policy",
  PUBLISH_POLICY_CHAMPION_CHALLENGER = "endpoints_publish_policy_champion_challenger",
  PUBLISH_POLICY_CANARY = "endpoints_publish_policy_canary",
  PUBLISH_POLICY_CONFIRM = "publish_policy_confirm",
  PUBLISH_POLICY_CANCEL = "publish_policy_cancel",
  FULL_ROLLOUT_DATE_RANGE = "endpoints_publish_policy_full_rollout_date_range",
  FULL_ROLLOUT_CREATED_BY = "endpoints_publish_policy_full_rollout_created_by",
  FULL_ROLLOUT_CANCEL = "endpoints_publish_policy_full_rollout_cancel",
  FULL_ROLLOUT_PUBLISH = "endpoints_publish_policy_full_rollout_publish",
  FULL_ROLLOUT_PUBLISH_POLICY = "endpoints_publish_policy_full_rollout_publish_publish_policy",
}

// Enums for Custom API Actions
export enum CustomAPIActions {
  ADD_SOURCE = "custom_api_add_source",
  ADD_SOURCE_RUN_TEST = "custom_api_add_source_run_test",
  ADD_SOURCE_SAVE = "custom_api_add_source_save",
  ADD_SOURCE_CANCEL = "custom_api_add_source_cancel",
  ADD_SOURCE_CLOSE = "custom_api_add_source_close",
  DELETE = "custom_api_delete",
  UPDATE_SOURCE_RUN_TEST = "custom_api_update_source_run_test",
  UPDATE_SOURCE_SAVE = "custom_api_update_source_save",
  UPDATE_SOURCE_CANCEL = "custom_api_update_source_cancel",
  UPDATE_SOURCE_CLOSE = "custom_api_update_source_close",
  API_STORE_CANCEL = "api_store_cancel",
  API_STORE_CLOSE = "api_store_close",
  API_STORE_UPDATE = "api_store_update",
}

// Enums for Lookup Actions
export enum LookupActions {
  ADD_SOURCE = "lookup_add_source",
  ADD_SOURCE_UPLOAD = "lookup_add_source_upload",
  ADD_SOURCE_SAVE = "lookup_add_source_save",
  ADD_SOURCE_CANCEL = "lookup_add_source_cancel",
  ADD_SOURCE_CLOSE = "lookup_add_source_close",
  DELETE = "lookup_delete",
  UPDATE_SOURCE_SAVE = "lookup_update_source_save",
  UPDATE_SOURCE_CANCEL = "lookup_update_source_cancel",
  UPDATE_SOURCE_CLOSE = "lookup_update_source_close",
}

// Enums for Reports Actions
export enum ReportsActions {
  OUTCOMES_OUTCOMES = "reports_outcomes_outcomes",
  OUTCOMES_PROGRAMS = "reports_outcomes_programs",
  OUTCOMES_POLICY_BUCKET = "reports_outcomes_policy_bucket",
  OUTCOMES_POLICIES = "reports_outcomes_policies",
  OUTCOMES_DATE_RANGE = "reports_outcomes_date_range",
  OUTCOMES_NEXT_PAGE = "reports_outcomes_next_page",
  OUTCOMES_GENERATE_REPORT = "reports_outcomes_generate_report",
  OUTCOMES_GENERATE_REPORT_SUBMIT = "reports_outcomes_generate_report_submit",
  EVALUATIONS_PAGE_CHANGE = "reports_evaluations_page_change",
  EVALUATIONS_VIEW_WORKFLOW = "reports_evaluations_view_workflow",
  EVALUATIONS_EXPAND_WORKFLOW = "reports_evaluations_expand_workflow",
  ALL_REPORT = "all_report",
  GENERATE_REPORT = "reports_all_reports_generate_report",
  GENERATE_REPORT_SUBMIT = "reports_all_reports_generate_report_generate",
  LIST_FILTER = "reports_list_filter",
  DOWNLOAD = "reports_download",
}

// Enums for Outcome Details Actions
export enum OutcomeDetailsActions {
  VIEW_WORKFLOW = "outcome_details_view_workflow",
  OPEN_IN_STUDIO = "outcome_details_open_in_studio",
  OVERVIEW_TAB = "outcome_details_overview_tab",
  DECISION_TAB = "outcome_details_decision_tab",
  PARAMETER_TAB = "outcome_details_parameter_tab",
  PREDICTOR_TAB = "outcome_details_predictor_tab",
  DOWNLOAD_PREDICTORS = "outcome_details_download_predictors",
  DECISION_BLOCK_TYPE = "outcome_details_decision_block_type",
  PARAMETER_VIEW_TABLE = "outcome_details_parameter_view_table",
  DOWNLOAD_CAM_REPORT = "outcome_details_download_cam_report",
}

// Enums for Simulation Actions
export enum SimulationActions {
  LIST = "simulation_list",
  FILTER = "simulations_filter",
  RUN_SIMULATION_MODAL = "simulations_run_simulation_modal",
  RUN_SIMULATION_SUBMIT = "simulations_run_simulation_submit",
  DOWNLOAD_TEMPLATE = "simulations_download_template",
}

// Enums for Analytics Actions
export enum AnalyticsActions {
  NEW_DASHBOARD = "analytics_new_dashboard",
  DASHBOARD_CREATE = "analytics_dashboard_create",
  PLATFORM_TEMPLATES_FILTER = "analytics_platform_templates_filter",
  SAVED_TEMPLATES_DELETE = "analytics_saved_templates_delete",
  ADD_CHART = "analytics_add_chart",
  ADD_CHART_CREATE = "analytics_add_chart_create",
  ADD_CHART_CANCEL = "analytics_add_chart_cancel",
  ADD_CHART_CLOSE = "analytics_add_chart_close",
  SAVED_TEMPLATE_EXPAND = "analytics_saved_template_expand",
  SAVED_TEMPLATE_EDIT = "analytics_saved_template_edit",
  SAVED_TEMPLATE_UPDATE = "analytics_saved_template_update",
  SAVED_TEMPLATE_CANCEL = "analytics_saved_template_cancel",
  SAVED_TEMPLATE_CLOSE = "analytics_saved_template_close",
  SAVED_TEMPLATE_DUPLICATE = "analytics_saved_template_duplicate",
  SAVED_TEMPLATE_DUPLICATE_ADD_CHART = "analytics_saved_template_duplicate_add_chart",
}

// Enums for Monitors Actions
export enum MonitorsActions {
  ENDPOINT_ADD = "monitors_endpoint_add",
  ENDPOINT_ADD_CANCEL = "monitors_endpoint_add_cancel",
  ENDPOINT_ADD_CLOSE = "monitors_endpoint_add_close",
  ENDPOINT_ADD_CREATE = "monitors_endpoint_add_create",
  ENDPOINT_MONITOR = "monitors_endpoint_monitor",
  ENDPOINT_EDIT_CANCEL = "monitors_endpoint_edit_cancel",
  ENDPOINT_EDIT_CLOSE = "monitors_endpoint_edit_close",
  ENDPOINT_EDIT_SAVE = "monitors_endpoint_edit_save",
  ENDPOINT_EDIT_DELETE = "monitors_endpoint_edit_delete",
  ENDPOINT_VIEW_LOGS = "monitors_endpoint_view_logs",
  CUSTOM_API_ADD = "monitors_custom_api_add",
  CUSTOM_API_ADD_CANCEL = "monitors_custom_api_add_cancel",
  CUSTOM_API_ADD_CLOSE = "monitors_custom_api_add_close",
  CUSTOM_API_ADD_CREATE = "monitors_custom_api_add_create",
  CUSTOM_API_MONITOR = "monitors_custom_api_monitor",
  CUSTOM_API_EDIT_CANCEL = "monitors_custom_api_edit_cancel",
  CUSTOM_API_EDIT_CLOSE = "monitors_custom_api_edit_close",
  CUSTOM_API_EDIT_SAVE = "monitors_custom_api_edit_save",
  CUSTOM_API_EDIT_DELETE = "monitors_custom_api_edit_delete",
  CUSTOM_API_VIEW_LOGS = "monitors_custom_api_view_logs",
  ALERT_LOGS_FILTER = "monitors_alert_logs_filter",
  ALERT_LOGS_DETAILS = "monitors_alert_logs_details",
}

// Enums for Settings Actions
export enum SettingsActions {
  SAVE = "settings_save",
  ALL_PROGRAMS = "settings_all_programs",
  ALL_PROGRAMS_ADD_PROGRAM = "settings_all_programs_add_program",
  ALL_PROGRAMS_ADD_PROGRAM_CREATE = "settings_all_programs_add_program_create",
  MEMBERS = "settings_members",
  MEMBERS_FILTER = "settings_members_filter",
  MEMBERS_INVITE = "settings_members_invite",
  MEMBERS_SEND_INVITE = "settings_members_send_invite",
  ROLES = "settings_roles",
  ROLES_CREATE = "settings_roles_create",
  ROLES_CREATE_NEW_ROLE = "settings_roles_create_new_role",
  ROLES_CREATE_ADD_RULE = "settings_roles_create_add_rule",
  ROLES_CREATE_ADD_RULE_CONFIRM = "settings_roles_create_add_rule_confirm",
  ROLES_CREATE_SAVE = "settings_roles_create_save",
  ROLES_CREATE_CANCEL = "settings_roles_create_cancel",
  ENTERPRISE_LOGIN = "settings_enterprise_login",
  WEBHOOKS = "settings_webhooks",
}

// Enums for Help Actions
export enum HelpActions {
  GETTING_STARTED = "help_getting_started",
  AUTHENTICATION_GUIDE = "help_authentication_guide",
  PROGRAM = "help_programme",
  JOURNEY = "help_journey",
  ENDPOINTS = "help_endpoints",
  SIMULATIONS = "help_simulations",
  FULL_ROLLOUT = "help_full_rollout",
  CC = "help_cc",
  CANARY = "help_canary",
  ALERTS = "help_alerts",
  ANALYTICS = "help_analytics",
  RISK_CONNECTORS = "help_risk_connectors",
  GETTING_STARTED_API = "help_getting_started_api",
  AUTHENTICATION_API = "help_authentication_api",
  ERRORS_API = "help_errors_api",
  LANGUAGE_DEF = "help_language_def",
  API_SPECS = "help_api_specs",
  VIDEOS_INVITE = "help_videos_invite",
  VIDEOS_CREATE_POLICY = "help_videos_create_policy",
  VIDEOS_PUBLISH_POLICY = "help_videos_publish_policy",
  VIDEOS_OUTCOMES_AND_DETAILS = "help_videos_outcomes_and_details",
  VIDEOS_CREATE_ANALYTICS_TEMPLATES = "help_videos_create_analytics_templates",
  VIDEOS_CREATE_MONITORS = "help_videos_create_monitors",
}

// Enums for Webhooks
export enum WebhookActions {
  WEBHOOKS_ADD = "webhooks_add_webhook",
  CONFIGURE_WEBHOOK = "configure_webhook",
  CONFIGURE_WEBHOOK_SAVE = "configure_webhook_save",
  EDIT_WEBHOOK = "webhook_edit",
  EDIT_WEBHOOK_SAVE = "webhook_edit",
  DELETE_WEBHOOK = "webhook_delete",
}
