import { JsonNodeType } from "./JsonInput/JsonInput.types";

export enum InputParamDataEnum {
  Text = "text",
  Number = "number",
  Boolean = "boolean",
  Object = "object",
}

export enum InputParamOperation {
  ADD = "add",
  DELETE = "delete",
  EDIT = "edit",
}

export interface WorkflowCustomInputType {
  dataType: InputParamDataEnum;
  defaultInput: string | null;
  name: string;
  isNullable: boolean;
  id: string;
  operation: InputParamOperation;
  schema?: JsonNodeType | null;
}

export interface SaveInputType extends Omit<WorkflowCustomInputType, "id"> {
  parentID?: string;
  isArray?: boolean;
  id?: string;
}
export type FormType = {
  inputs: Array<WorkflowCustomInputType>;
};

export interface JSONConfigType {
  id: string;
  index: number;
  schema?: JsonNodeType | null;
}

export type InputError = Array<Record<string, string> | undefined>;
