import { AxiosResponse } from "axios";
import { XYPosition } from "reactflow";
import axios from "@axios";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";
import {
  WfChangelog,
  WfChangelogVersionList,
} from "./components/ChangeLog/types";
import {
  SaveInputType,
  WorkflowCustomInputType,
} from "./studio/components/InputParameters/InputParameters.types";
import {
  ErrorResponse,
  EvalTestData,
  FinalDecision,
  NodeImportData,
  PredictorsList,
  RuleGroup,
  Settings,
  Workflow,
  WorkflowAudit,
  WorkflowEvent,
  WorkflowKeywords,
} from "./types";

export const useUpdateWorkflow = (workflowID?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: WorkflowEvent) =>
      axios.post<FinBoxResponse<any>>("workflow/event", body),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["getWorkflow", workflowID] }),
  });
};

export const useUpdateWorkflowMeta = () =>
  useMutation({
    mutationFn: ({
      workflowID,
      metadata,
    }: {
      workflowID: string;
      metadata: Object;
    }) => {
      return axios.post<FinBoxResponse<any>>("workflow/metadata", {
        workflowID,
        metadata,
      });
    },
  });

export const getWorkflowQuery = (id?: string) =>
  queryOptions({
    queryKey: ["getWorkflow", id],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<{
          workflow: Workflow;
        }>
      >(`/workflow/${id}`);
    },
    select: (data) => {
      const workflow = data.data?.data?.workflow;

      const versionArr = workflow?.name.match(/v\d+\.\d+\.\d+/) ?? [];
      const version = versionArr[0] ?? "";
      workflow.policyVersion = version;
      workflow.policyName = workflow?.name.replace(version, "");

      return data.data?.data?.workflow;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

export const useGetWorkflow = (id: string | undefined) =>
  useQuery(getWorkflowQuery(id));

export const getWorkflowAuditQuery = (id?: string) =>
  queryOptions({
    queryKey: ["getWorkflow", id],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<{
          audit: WorkflowAudit;
          workflow: Workflow;
        }>
      >(`/workflow/${id}`, {
        params: {
          activityLog: true,
        },
      });
    },
    select: (data) => data.data?.data,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

export const useGetWorkflowAudit = (id: string | undefined) =>
  useQuery(getWorkflowAuditQuery(id));

export const getWorkflowRulesQuery = (id?: string) =>
  queryOptions({
    queryKey: ["getWorkflowRules", id],
    queryFn: async () => {
      return axios.get<FinBoxResponse<Array<RuleGroup>>>(
        `/workflow/rules/${id}`
      );
    },
    select: (data) => data.data?.data,
    enabled: !!id,
  });

export const useGetRules = (id: string | undefined) =>
  useQuery(getWorkflowRulesQuery(id));

export const useRunWorkflowTest = (
  onSettled?: (data: any) => void,
  onSuccess?: (
    data: AxiosResponse<FinBoxResponse<FinalDecision & EvalTestData>>
  ) => void
) =>
  useMutation({
    mutationFn: ({
      workflowID,
      testData,
    }: {
      workflowID: string;
      testData: any;
    }) =>
      axios.post<FinBoxResponse<FinalDecision & EvalTestData>>(
        "workflow/test",
        {
          workflowID,
          testData,
        }
      ),
    onSettled: (data) => {
      onSettled && onSettled(data?.data?.data);
    },
    onSuccess,
  });

export const useUpdateRuleGroup = () => {
  return useMutation({
    mutationFn: ({
      workflowID,
      ruleGroupID,
      body,
      name,
      outputs,
      predictors,
    }: {
      workflowID: string;
      ruleGroupID: string;
      body: string;
      name: string;
      outputs: string[];
      predictors: { [x: string]: string[] } & {
        policies: Record<string, string[]>;
      };
    }) => {
      return axios.post<FinBoxResponse>(
        `workflow/${workflowID}/ruleGroup/${ruleGroupID}`,
        {
          body,
          output: outputs,
          name,
          predictors,
        }
      );
    },
    onError: (err) => {
      notify({
        text: getNetworkErrorText(err),
        title: "Failed to save",
        type: "error",
      });
    },
  });
};

export function useTestBranch() {
  return useMutation({
    mutationFn: (body: {
      branch: Array<{ condition: string; output: string }>;
      variables?: Record<string, any>;
    }) => axios.v2.post<FinBoxResponse<any>>("eval", body),
  });
}

export function useGetPredictors(
  expressions: string[],
  includeSameNodeModels: boolean = false,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: ["expressionPredictors", expressions],
    select: (data) => data.data.data,
    queryFn: async () =>
      axios.post<
        FinBoxResponse<
          Partial<{
            policies: Record<string, string[]>;
            workflows: Record<string, string[]>;
          }> &
            Record<string, string[]>
        >
      >("expressionPredictors", { expressions, includeSameNodeModels }),
    meta: {
      errorMessage: "Could not fetch predictors",
    },
    enabled,
  });
}

export function useExportWf() {
  return useMutation({
    mutationFn: async ({ workflowId }: { workflowId: string }) =>
      axios.post<FinBoxResponse<string>>(`workflow/export/${workflowId}`),
    onSuccess: (data) => window.open(data.data.data, "_blank"),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
}

export const useSimulation = (id: number | undefined) =>
  useQuery({
    queryKey: ["useSimulation", id],
    queryFn: async () => {
      return axios.get(`simulation`);
    },
    select: (data) => data?.data?.data,
    enabled: !!id,
  });

type ImportWfData = {
  name: string;
  description: string;
  programOutcomeTemplateID: string;
  content: {
    newMappedPolicies: string;
    newMappedWorkflows: string;
    metadata: Record<string, XYPosition>;
    policies: Record<string, string>;
    ruleGroups: Record<string, any>;
    schema: Record<string, any>;
    workflows: Record<string, string>;
  };
};

export function useImportWf() {
  return useMutation({
    mutationFn: async (importData: ImportWfData) =>
      axios.post("workflow/import", importData),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
}

export const getRuleGroupQuery = (workflowID: string, ruleGroupID: string) =>
  queryOptions({
    queryKey: ["getRuleGroup", workflowID, ruleGroupID],
    queryFn: async () => {
      return axios.get<FinBoxResponse<RuleGroup>>(
        `workflow/${workflowID}/ruleGroup/${ruleGroupID}`
      );
    },
    select: (data) => data.data.data,
    enabled: !!workflowID && !!ruleGroupID,
    placeholderData: keepPreviousData,
    meta: {
      errorMessage: "Couldn't fetch the rule group details",
    },
  });

export const useUpdateWorkflowOutcomeConfig = () => {
  return useMutation({
    mutationFn: ({
      workflowId,
      variables,
    }: {
      workflowId: string;
      variables: Array<{
        name: string;
        type: "text" | "number" | "boolean";
      }>;
    }) => axios.post(`workflow/${workflowId}/globalConfig`, variables),
  });
};

export const getWorkflowOutcomeConfig = (workflowId: string) => {
  return queryOptions({
    queryKey: ["workflowOutcomeConfig", workflowId],
    queryFn: () =>
      axios.get<
        FinBoxResponse<
          Array<{
            name: string;
            type: "text" | "number" | "boolean";
            isMandatory: boolean;
          }>
        >
      >(`workflow/${workflowId}/globalConfig`),
    // It's invalidated after being updated, and it's updated from only one place.
    staleTime: Infinity,
  });
};

export function useUpdateWorkflowName() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      name,
      workflowId,
      description,
    }: {
      name: string;
      workflowId: string;
      description: string;
    }) =>
      axios.post("workflow/update", {
        workflowID: workflowId,
        name: name,
        description: description,
      }),
    onSuccess: (_, vars) =>
      queryClient.invalidateQueries(getWorkflowQuery(vars.workflowId)),
  });
}

type WorkflowPredictors = {
  policy: Array<{ name: string; id: string; outcomes: string[] }>;
  workflow: Array<{ name: string; id: string; outcomes: string[] }>;
  variables: Record<Exclude<string, "policies" | "workflows">, string[]> & {
    policies?: Record<string, string[]>;
    workflows?: Record<string, string[]>;
  };
};

export function getWorkflowPredictors(workflowId?: string) {
  return queryOptions({
    queryKey: ["workflowPredictors", workflowId],
    queryFn: async () =>
      axios.get<FinBoxResponse<WorkflowPredictors>>(
        `workflow/${workflowId}/predictors`
      ),
    enabled: !!workflowId,
  });
}

export function useImportNode() {
  return useMutation({
    mutationFn: async ({
      workflowId,
      data,
    }: {
      workflowId?: string;
      data: NodeImportData;
    }) => {
      if (workflowId)
        return axios.post<FinBoxResponse<string>>(
          `/workflow/${workflowId}/node`,
          data
        );
    },
  });
}

export function getErrors(workflowId?: string) {
  return queryOptions({
    queryKey: ["workflowErrors", workflowId],
    queryFn: async () =>
      axios.get<FinBoxResponse<ErrorResponse>>(
        `workflow/${workflowId}/errorStates`
      ),
    enabled: !!workflowId,
  });
}

export function useSaveSettings() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      settings,
    }: {
      workflowId: string;
      settings: Settings;
    }) => {
      return axios.post(`workflow/${workflowId}/settings`, settings);
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(
        getCustomInputsQuery(vars.workflowId, "workflow")
      );
      queryClient.invalidateQueries(getWorkflowQuery(vars.workflowId));
      notify({
        title: "Saved",
        text: "Settings have been updated",
        type: "success",
      });
    },
    onError: (err) => {
      notify({
        title: "Failed",
        text: getNetworkErrorText(err),
      });
    },
  });
}

export function getWorkflowKeywordsQuery(workflowId?: string) {
  return queryOptions({
    queryKey: normalizeQueryKey(["workflowKeywords", workflowId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<WorkflowKeywords>>(
        `workflow/${workflowId}/getKeywords`
      ),
    enabled: !!workflowId,
    staleTime: Infinity,
    select: (d) => {
      const data = d.data.data;
      const functions = data.common.functions;
      const inputs = data.common.inputs;
      const args = data.common.args;
      const pluginSources: PredictorsList = {};
      const customSources: PredictorsList = {};
      const modelSet: PredictorsList = {};
      const ruleSet: PredictorsList = {};
      const lookupsSource: PredictorsList = {};
      const inputSources: PredictorsList = {};
      const policies: PredictorsList = data.predictors.policies ?? {};
      const workflows: PredictorsList = data.predictors.workflows ?? {};

      for (const source of Object.keys(data.predictors)) {
        if (source === "policies" || source === "workflows") continue;
        const value = data.predictors[source];
        if (!Array.isArray(value)) {
          if (value.type === 1) pluginSources[source] = value;
          else if (value.type === 3) customSources[source] = value;
          else if (value.type === 5) {
            modelSet[source] = value;
          } else if (value.type === 6) {
            ruleSet[source] = value;
          } else if (value.type === 4) {
            lookupsSource[source] = value;
          } else;
        }
      }

      const dependents: Record<
        string,
        {
          policies: string[];
          workflows: string[];
          sources: string[];
        }
      > = {};
      for (let [node, deps] of Object.entries(data.dependents)) {
        if (!node) continue;
        if (!deps) deps = [];
        dependents[node] = {
          policies: [],
          workflows: [],
          sources: [],
        };
        for (let source of deps) {
          if (typeof source === "string") dependents[node].sources.push(source);
          else {
            deps.forEach((dependent) => {
              if (typeof dependent !== "string") {
                const { workflows, policies } = dependent;
                if (Array.isArray(workflows)) {
                  dependents[node].workflows.push(...workflows);
                } else if (Array.isArray(policies)) {
                  dependents[node].policies.push(...policies);
                }
              }
            });
          }
        }
      }

      return {
        functions,
        inputs,
        args,
        dependents,
        predictors: {
          policies,
          workflows,
          modelSet,
          ruleSet,
          pluginSources,
          customSources,
          lookupsSource,
          inputSources,
        },
      };
    },
  });
}
export function getConfigureWorkflowKeywordsQuery(workflowId?: string) {
  return queryOptions({
    queryKey: normalizeQueryKey(["workflowKeywords", workflowId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<WorkflowKeywords>>(
        `workflow/${workflowId}/getKeywords`
      ),
    enabled: !!workflowId,
    staleTime: Infinity,
    select: (d) => {
      const data = d.data.data;
      const functions = data.common.functions;
      const input = data.common.inputs;
      const args = data.common.args;

      const pluginSources: PredictorsList = {};
      const customSources: PredictorsList = {};
      const modelSet: PredictorsList = {};
      const ruleSet: PredictorsList = {};
      const lookupsSource: PredictorsList = {};
      const policies: PredictorsList = data.predictors.policies ?? {};
      const workflows: PredictorsList = data.predictors.workflows ?? {};

      for (const source of Object.keys(data.predictors)) {
        if (source === "policies" || source === "workflows") continue;
        const value = data.predictors[source];
        if (!Array.isArray(value)) {
          if (value.type === 1) pluginSources[source] = value;
          else if (value.type === 3) customSources[source] = value;
          else if (value.type === 5) modelSet[source] = value;
          else if (value.type === 6) ruleSet[source] = value;
          else if (value.type === 4) lookupsSource[source] = value;
        }
      }

      const dependents: Record<
        string,
        {
          policies: string[];
          workflows: string[];
          ruleSet: string[];
          modelSet: string[];
          lookupsSource: string[];
          pluginSources: string[];
          customSources: string[];
        }
      > = {};

      for (const [node, deps] of Object.entries(data.dependents)) {
        if (!node || !deps) continue;

        dependents[node] = {
          policies: [],
          workflows: [],
          ruleSet: [],
          modelSet: [],
          lookupsSource: [],
          pluginSources: [],
          customSources: [],
        };

        deps.forEach((dep) => {
          if (typeof dep === "string") {
            if (policies[dep]) dependents[node].policies.push(dep);
            else if (workflows[dep]) dependents[node].workflows.push(dep);
            else if (ruleSet[dep]) dependents[node].ruleSet.push(dep);
            else if (modelSet[dep]) dependents[node].modelSet.push(dep);
            else if (lookupsSource[dep])
              dependents[node].lookupsSource.push(dep);
            else if (pluginSources[dep])
              dependents[node].pluginSources.push(dep);
            else if (customSources[dep])
              dependents[node].customSources.push(dep);
          } else if (typeof dep === "object") {
            for (const [key, value] of Object.entries(dep)) {
              if (key === "policies" && Array.isArray(value)) {
                dependents[node].policies.push(
                  ...value.filter((v) => policies[v])
                );
              } else if (key === "workflows" && Array.isArray(value)) {
                dependents[node].workflows.push(
                  ...value.filter((v) => workflows[v])
                );
              } else if (key === "ruleSet" && Array.isArray(value)) {
                dependents[node].ruleSet.push(
                  ...value.filter((v) => ruleSet[v])
                );
              } else if (key === "modelSet" && Array.isArray(value)) {
                dependents[node].modelSet.push(
                  ...value.filter((v) => modelSet[v])
                );
              } else if (key === "lookupsSource" && Array.isArray(value)) {
                dependents[node].lookupsSource.push(
                  ...value.filter((v) => lookupsSource[v])
                );
              } else if (key === "pluginSources" && Array.isArray(value)) {
                dependents[node].pluginSources.push(
                  ...value.filter((v) => pluginSources[v])
                );
              } else if (key === "customSources" && Array.isArray(value)) {
                dependents[node].customSources.push(
                  ...value.filter((v) => customSources[v])
                );
              }
            }
          }
        });
      }
      return {
        functions,
        input,
        args,
        dependents,
        predictors: {
          policies,
          workflows,
          modelSet,
          ruleSet,
          pluginSources,
          customSources,
          lookupsSource,
        },
      };
    },
  });
}

export function getChangeLog(
  bucketId: string,
  fromWfId: string,
  toWfId: string
) {
  return queryOptions({
    queryKey: normalizeQueryKey(["changelog", bucketId, fromWfId, toWfId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<WfChangelog>>(
        `policybucket/${bucketId}/changelog/generate`,
        {
          params: {
            fromwfid: fromWfId,
            towfid: toWfId,
          },
        }
      ),
    select: (data) => data.data,
    enabled: !!bucketId,
  });
}

export function getChangeLogWfVersionList(bucketId: string, toWfId: string) {
  return queryOptions({
    queryKey: normalizeQueryKey(["changelog_version_list", bucketId, toWfId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<WfChangelogVersionList[]>>(
        `policybucket/${bucketId}/changelog/list`,
        {
          params: {
            wfID: toWfId,
          },
        }
      ),
    select: (data) => data.data.data,
    enabled: !!bucketId,
  });
}

export function getIsChangeLogGenerated(bucketId: string, toWfId: string) {
  return queryOptions({
    queryKey: normalizeQueryKey(["changelog_generated", bucketId, toWfId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<string>>(
        `policybucket/${bucketId}/changelog/fromWorkflow`,
        {
          params: {
            wfID: toWfId,
          },
        }
      ),
    select: (data) => data.data.data,
    enabled: !!bucketId,
    staleTime: 1000,
  });
}

export const getCustomInputsQuery = (policyId?: string, policyType?: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["getInput", policyId, policyType]),
    queryFn: async () => {
      return axios.v2.get<FinBoxResponse<WorkflowCustomInputType[]>>(
        `/inputs?policyType=${policyType}&policyID=${policyId}`
      );
    },
    select: (data) => data.data,
  });

export const useGetCustomInputs = (
  policyId: string,
  policyType: string = "normal"
) => useQuery(getCustomInputsQuery(policyId, policyType));

export const useSaveWorkflowCustomInputs = () =>
  useMutation({
    mutationFn: ({
      policyID,
      inputs,
      policyType = "normal",
    }: {
      policyID: string;
      inputs: SaveInputType[];
      policyType?: string;
    }) =>
      axios.v2.post<FinBoxResponse<any>>("workflow/saveInputs", {
        policyID,
        inputs,
        policyType,
      }),
  });
