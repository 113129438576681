import { stringify as csvStringify } from "csv-stringify/browser/esm/sync";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Index_Regex } from "src/constants/appLevel";
import plusIcon from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import closeIcon from "@assets/icons/x-close.svg";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import MatrixHeader from "@screens/workflow/studio/components/DecisionTable/TableHeader";
import { transformColumnArrayToRequest } from "@screens/workflow/studio/components/DecisionTable/utils";
import ModelSetTableTest from "@screens/workflow/studio/components/ModelSet/ModelSetTableTest";
import {
  getModelSetItemsQuery,
  useUpdateModelSetItem,
} from "@screens/workflow/studio/components/ModelSet/queries";
import { DecisionTableRules } from "@screens/workflow/studio/components/ModelSet/types";
import { NodeName } from "@screens/workflow/studio/components/NodeName";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { ModelSetId } from "@screens/workflow/types";
import Button from "@components/Button";
import CustomEditor from "@components/Editor/CustomEditor";
import Label from "@components/Label";
import { useQueryClient } from "@tanstack/react-query";
import {
  classNames,
  generateAndDownloadFile,
  getNetworkErrorText,
  notify,
} from "@utils/utils";
import useDecisionTable from "../../hooks/useDecisionTable";
import ModelSetTableRow from "./ModelSetTableRow";

export default function ModelSetTableEditor() {
  const { workflowId, nodeId, itemId } = useParams<{
    workflowId: string;
    nodeId: string;
    itemId: string;
  }>();

  const [showTest, setShowTest] = useState(false);

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, nodeId);

  const queryClient = useQueryClient();

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  const nodeData =
    queryClient.getQueryData(getModelSetItemsQuery(workflowId, nodeId).queryKey)
      ?.data.data ?? [];

  const matrixItemData = nodeData.find((i) => i.id === itemId)!;

  const availableModelNames = nodeData
    .filter((item) => item.seqNo < matrixItemData.seqNo)
    .map((item) => item.name);

  const matrixName = matrixItemData?.name ?? "";

  const matrixData =
    matrixItemData?.decisionTableRules ??
    ({
      default: "",
      headers: [],
      rows: [],
    } as DecisionTableRules);

  const {
    tableData,
    addDecisionTableRow,
    deleteDecisionTableRow,
    addDecisionTableColumn,
    deleteDecisionTableColumn,
    handleColumnChange,
    handleCellDataChange,
    importDecisionTable,
    handleCellEdit,
    ref,
    lastColumnRef,
    fileInputRef,
    currentlyEditing,
    setCurrentlyEditing,
    isWorkflowEditable,
    setDefaultDecisionTableValue,
    keyPressHandler,
  } = useDecisionTable({ matrixData });

  function checkIsEditing(row: number, col: number) {
    if (!currentlyEditing) return false;
    return row === currentlyEditing[0] && col === currentlyEditing[1];
  }

  const navigate = useNavigate();
  const updateMutation = useUpdateModelSetItem(workflowId!, nodeId!, itemId!);

  useEffect(() => {
    const listener = (e: globalThis.KeyboardEvent) => {
      if (e.key !== "Tab") return;
      if (!currentlyEditing) return setCurrentlyEditing([0, 0]);

      const [row, col] = currentlyEditing;
      const headerCount = matrixData.headers.length ?? 0;

      if (row === -1) {
        if (col === headerCount - 1) return setCurrentlyEditing([0, 0]);
      }

      if (col === headerCount) {
        return setCurrentlyEditing([row + 1, 0]);
      }

      setCurrentlyEditing([row, col + 1]);
    };
    document.addEventListener("keypress", listener);
    return () => document.removeEventListener("keypress", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyEditing, matrixData?.headers?.length]);

  if (!matrixItemData) navigate(`/workflow/${workflowId}`);

  const exportTable = () => {
    if (!matrixData) return;
    const data = csvStringify([
      [...matrixData.headers, "output"],
      ...matrixData.rows.map((row) => [
        ...matrixData.headers.map((_, i) => row?.columns?.[i]?.value),
        row.output,
      ]),
    ]);

    generateAndDownloadFile(data, `${matrixName}.csv`);
  };

  const updateMatrix = () => {
    const isIndexMissed = tableData.headers.some((header) =>
      Index_Regex.test(header.name)
    );
    if (isIndexMissed) {
      notify({ title: "Failed", text: "Please Specify index" });
      return;
    }
    updateMutation.mutate(
      {
        expression: {
          decisionTableRules: transformColumnArrayToRequest(tableData),
          name: matrixName,
          seqNo: matrixItemData!.seqNo,
          body: "",
        },
      },
      {
        onSuccess: () =>
          notify({ title: "Saved", text: "Updated table", type: "success" }),
        onError: (err) =>
          notify({ title: "Failed", text: getNetworkErrorText(err) }),
      }
    );
  };

  const closeTable = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/workflow/${workflowId}`);
  };

  const updateNodeName = async (name: string) => {
    await updateMutation.mutateAsync({
      expression: {
        name,
        decisionTableRules: matrixData,
        seqNo: matrixItemData?.seqNo,
        body: "",
      },
    });
    queryClient.invalidateQueries(getWorkflowKeywordsQuery());
  };

  let nodeErrors: string[] = [];
  if (itemId)
    nodeErrors =
      queryClient
        .getQueryData(getErrors(workflowId).queryKey)
        ?.data.data[nodeId as ModelSetId]?.errors[itemId]?.split(",") ?? [];

  const disableSave = tableData.rows.some((item) => !item.output);

  return (
    <div className="fixed inset-0 w-screen h-screen bg-white py-3 z-[100] flex">
      {showTest && (
        <div className="basis-1/3 relative pl-4">
          <ModelSetTableTest
            expressions={[matrixData.default, ...matrixData.headers]}
          />
        </div>
      )}
      <div
        className={classNames(
          "pr-4 border-l border-neutral-100 pl-4 overflow-auto",
          showTest ? "basis-2/3" : "basis-full"
        )}
      >
        <div className="font-p3-medium flex justify-between items-center">
          <NodeName
            onChange={(name) => updateNodeName(name)}
            defaultName={matrixName || "Table"}
          />
          <img
            alt="x"
            src={closeIcon}
            onClick={closeTable}
            className="h-4 w-4 p-0 ml-auto cursor-pointer z-10 text-neutral-500"
          />
        </div>

        <div className="flex flex-col gap-4 mt-3 px-5">
          <div className="flex justify-between items-center">
            <div className="w-[248px]">
              <Label>Default Value</Label>
              <CustomEditor
                value={tableData.default}
                setValue={setDefaultDecisionTableValue}
                monacoOptions={{
                  lineNumbers: "off",
                  glyphMargin: false,
                  fontWeight: "400",
                  folding: false,
                  lineDecorationsWidth: 0,
                  lineNumbersMinChars: 0,
                  showFoldingControls: "never",
                }}
              />
            </div>
            <Button
              onClick={() => setShowTest(!showTest)}
              className="ml-auto mr-2"
              variant="outline"
            >
              Test
            </Button>
            <Button onClick={exportTable} className="mr-2" variant="outline">
              Export
            </Button>
            {isWorkflowEditable && (
              <>
                <input
                  accept=".csv"
                  onChange={importDecisionTable}
                  ref={fileInputRef}
                  className="hidden"
                  type="file"
                />
                <Button
                  onClick={() => fileInputRef.current?.click()}
                  className="mr-2"
                  variant="outline"
                >
                  Import
                </Button>
                <Button
                  onClick={addDecisionTableColumn}
                  className="mr-2"
                  variant="outline"
                >
                  <img src={plusIcon} alt="+" />
                  Add Column
                </Button>
                <Button onClick={() => updateMatrix()} disabled={disableSave}>
                  Save
                </Button>
              </>
            )}
          </div>
          <span className="text-error-500 font-b2">
            {nodeErrors.slice(0, 5).map((i) => {
              return (
                <Fragment key={i[1] + i[0]}>
                  {i}
                  <br />
                </Fragment>
              );
            })}
            {nodeErrors.length > 5 && (
              <Fragment>
                +{nodeErrors.length - 5} more errors
                <br />
              </Fragment>
            )}
          </span>
          <div
            ref={ref}
            className="border-t overflow-auto border-l font-b1 border-neutral-100 rounded-md grid divide-neutral-100 min-h-[1ch] max-h-[calc(100vh-250px)] z-0"
            style={{
              gridTemplateColumns: `75px repeat(${
                (tableData?.headers ?? []).length + 1
              }, minmax(250px, 1fr))`,
            }}
          >
            <div className="bg-neutral-0 sticky z-20 top-0 left-0 overflow-visible w-full font-medium first:rounded-tl-md py-1 px-4 border-r border-b border-neutral-100 h-10 flex items-center">
              Sl. No.
            </div>
            {(tableData?.headers ?? []).map(({ name }, index) => {
              return (
                <div
                  key={`colhead_${index}`}
                  ref={
                    index === tableData.headers.length - 1
                      ? lastColumnRef
                      : null
                  }
                  className="sticky z-10 top-0"
                >
                  {tableData.headers.length > 1 && isWorkflowEditable && (
                    <TrashIcon
                      onClick={() => deleteDecisionTableColumn(index)}
                      className="[&:hover>path]:stroke-error-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
                    />
                  )}
                  <MatrixHeader
                    modelNames={availableModelNames}
                    keywords={keywordsQuery}
                    tableName={matrixName}
                    data={name}
                    setIsEditing={() => setCurrentlyEditing([-1, index])}
                    isEditing={checkIsEditing(-1, index)}
                    headersList={tableData.headers}
                    value={tableData.headers[index].name}
                    colIndex={index}
                    handleColumnChange={handleColumnChange}
                  />
                </div>
              );
            })}
            <div className="sticky top-0 right-0 z-50 py-1 pl-4 pr-1 -ml-[1px] border-l border-r border-neutral-100 font-medium border-b bg-neutral-0 rounded-tr-md flex items-center">
              Output
            </div>
            {tableData.rows.map(({ column, output }, rowIndex) => {
              return (
                <ModelSetTableRow
                  key={rowIndex}
                  rowIndex={rowIndex}
                  column={column}
                  output={output}
                  getDeleteRow={deleteDecisionTableRow}
                  keyPressHandler={keyPressHandler}
                  handleCellEditing={handleCellEdit}
                  currentlyEditing={currentlyEditing}
                  handleChange={handleCellDataChange}
                />
              );
            })}
          </div>
        </div>
        {isWorkflowEditable && (
          <Button
            onClick={addDecisionTableRow}
            className={classNames(
              "self-start mt-3",
              (tableData?.headers ?? []).length === 0 && "hidden"
            )}
            variant="outline"
          >
            <img src={plusIcon} alt="+" />
            Add Row
          </Button>
        )}
      </div>
    </div>
  );
}
