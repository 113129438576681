import { useEffect, useState } from "react";
import Modal from "src/components/Dialogue";
import ProgressBar from "src/components/ProgressBar";

export default function TransformLoader({
  open,
  showText,
}: {
  open: boolean;
  showText: string;
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Only set interval when modal is open
    if (open) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 4 : 100));
      }, 500); // Adjust interval time as needed

      // Clear interval on unmount or when open changes to false
      return () => clearInterval(interval);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={() => {}}>
      <Modal.Panel size="lg" className="w-[650px] h-[150px]">
        <Modal.Body className="!h-[150px] !p-0 !m-0">
          <div className="w-full h-full p-6 flex justify-center items-center flex-col gap-4">
            <div className="w-full">
              <ProgressBar progressPerc={progress} />
            </div>
            <div className="w-full text-center">
              {progress < 100 ? showText : "Taking longer than expected"}
            </div>
          </div>
        </Modal.Body>
      </Modal.Panel>
    </Modal>
  );
}
