import axios from "@axios";
import {
  queryOptions,
  useMutation,
  useQuery
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, notify } from "src/utils/utils";
import { EventList, UpdateHookEvent, WebhookChannelDetails } from "./types";

export const getClientAllowedEvents = () => {
  return queryOptions({
    queryKey: ["getClientAllowedEvents"],
    queryFn: async () =>
      axios.get<FinBoxResponse<EventList>>("eventhook/events"),
    select: (data) => data.data.data,
  });
};
export function useGetClientAllowedEvents() {
  return useQuery(getClientAllowedEvents());
}

export const getWebhookChannelDetails = () => {
  return queryOptions({
    queryKey: ["getChannelDetails"],
    queryFn: async () =>
      axios.get<FinBoxResponse<WebhookChannelDetails>>(`eventhook/channel/1`),
    select: (data) => data.data.data,
  });
};
export function useWebhookChannelDetails() {
  return useQuery(getWebhookChannelDetails());
}

export const useAddHookEventChannel = () => {
  return useMutation({
    mutationFn: ({ data }: { data: UpdateHookEvent }) =>
      axios.post<FinBoxResponse<any>>("eventhook/channel", {
        channelSpecificData: data.channelSpecificData,
        channelType: data.channelType,
        events: data.events,
      }),
    onError: (e) => {
      notify({
        title: "Could not add webhook",
        text: getNetworkErrorText(e),
      });
    },
  });
};

export const useUpdateHookEventChannel = () => {
  return useMutation({
    mutationFn: ({
      data,
      channelId,
    }: {
      data: UpdateHookEvent;
      channelId: string;
    }) =>
      axios.patch<FinBoxResponse<any>>(`eventhook/channel/${channelId}`, {
        channelSpecificData: data.channelSpecificData,
        channelType: data.channelType,
        events: data.events,
      }),

    onError: (e) => {
      notify({
        title: "Could not update webhook",
        text: getNetworkErrorText(e),
      });
    },
  });
};

export const useDeleteHookEventChannel = () => {
  return useMutation({
    mutationFn: ({ channelId }: { channelId: string }) =>
      axios.delete<FinBoxResponse<any>>(`eventhook/channel/${channelId}`),

    onError: (e) => {
      notify({
        title: "Could not delete webhook",
        text: getNetworkErrorText(e),
      });
    },
  });
};
