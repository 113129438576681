import Button from "@components/Button";
import Wrapper from "@components/ContentWrapper";
import PrivateRoute from "@components/PrivateRoute";
import ShimmerFallbackUI from "@components/ShimmerFallbackUI";
import Topbar from "@components/Topbar";
import { BreadcrumbsProvider } from "@context/BreadcrumbsContext";
import { navigation } from "@doc/components/Layout";
import "@finbox-in/finblocks/dist/style.css";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import AddChartModal from "@screens/analytics/templates/modals/AddChartModal";
import CustomDataSources from "@screens/datasources/pages/CustomDataSources";
import FinBoxDataSources from "@screens/datasources/pages/FinBoxDataSources";
import MasterLookupTable from "@screens/datasources/pages/MasterLookupTable";
import DataDictionary from "@screens/docs/dataDictionary";
import ErrorScreen from "@screens/error";
import LoginWithToken from "@screens/login/LoginWithToken";
import Monitor from "@screens/monitor";
import Overview from "@screens/overview/Overview";
import PageNotFound from "@screens/PageNotFound";
import PolicyAnalytics from "@screens/policyDetails/pages/PolicyAnalytics";
import Reports from "@screens/reports";
import EditRoles from "@screens/settings/pages/SettingsAdmin/EditRoles";
import Members from "@screens/settings/pages/SettingsAdmin/Members";
import { Roles } from "@screens/settings/pages/SettingsAdmin/Roles";
import SetPassword from "@screens/user/SetPassword";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { lazy, Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Providers from "src/Providers";
import "./App.css";
import ProtectedComponent from "./components/ProtectedComponent";
import Sidebar from "./components/Sidebar";
import { PERMISSIONS_TYPE } from "./constants/permissionsConstant";
import WorkflowRouter from "./routes/WorkflowRouter";
import IntegrationWebhook from "./screens/settings/pages/IntegrationWebhook";
import { PermissionTypes } from "./types";

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const Md = lazy(() => import("@screens/docs/components/Md"));
const ForgotPassword = lazy(() => import("@screens/user/ForgotPassword"));
const SetForgotPassword = lazy(() => import("@screens/user/SetForgotPassword"));

const DataSource = lazy(() => import("@screens/datasources"));
const Decisions = lazy(() => import("@screens/reports/pages/outcomes"));
const PolicyDashboard = lazy(
  () => import("@screens/policy-dashboard/PolicyDashboard")
);
const Login = lazy(() => import("@screens/login/Login"));
const Simulations = lazy(
  () => import("@screens/reports/pages/simulations/Simulations")
);
const PolicyDetails = lazy(() => import("@screens/policyDetails"));
const OutcomeDetails = lazy(
  () => import("@screens/outcome-details/OutcomeDetails")
);
const CreatePolicy = lazy(() => import("@screens/create-policy"));
const Docs = lazy(() => import("@screens/docs"));
const EndpointDetails = lazy(
  () => import("@screens/endpoints/pages/endpoint-details")
);
const PublishPolicy = lazy(
  () => import("@screens/endpoints/pages/publish-policy")
);
const Settings = lazy(() => import("@screens/settings/Settings"));
const SettingsGeneral = lazy(
  () => import("@screens/settings/pages/SettingsGeneral")
);
const SettingsPrograms = lazy(
  () => import("@screens/settings/pages/SettingsPrograms")
);
const CustomMonitors = lazy(() => import("@screens/monitor/pages/Custom"));
const EndpointMonitors = lazy(() => import("@screens/monitor/pages/Endpoint"));
const AlertLogs = lazy(() => import("@screens/monitor/pages/AlertLogs"));
const GeneratedReports = lazy(
  () => import("@screens/reports/pages/generated-reports")
);
const Template = lazy(() => import("@screens/analytics/templates"));
const Analytics = lazy(() => import("@screens/analytics"));
const Endpoints = lazy(() => import("@screens/endpoints"));
const PolicyAnalyticsTemplate = lazy(
  () => import("@screens/analytics/templates/policy-analytics")
);

const PageWithSidebar = () => {
  return (
    <BreadcrumbsProvider>
      <div className="flex w-screen max-w-[100vw] h-screen">
        <Topbar />
        <Sidebar />
        <div
          className="grow bg-white"
          style={{
            width: `calc(100vw - 61px)`,
            paddingLeft: "48px",
          }}
        >
          <Suspense fallback={<ShimmerFallbackUI />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </BreadcrumbsProvider>
  );
};

const App = () => {
  return (
    <Providers>
      <Suspense fallback={<ShimmerFallbackUI />}>
        <BrowserRouter>
          <SentryErrorBoundary
            fallback={({ error, componentStack, resetError }) => {
              if (
                error.message.includes(
                  "Failed to fetch dynamically imported module"
                ) ||
                error.message.includes("Importing a module script failed")
              ) {
                window.location.reload();
                return <></>;
              }

              return ["DEV", "LOCAL"].includes(
                import.meta.env.REACT_APP_API_ENV
              ) ? (
                <div className="m-12 flex flex-col gap-5 text-red-800 font-bold">
                  <div className="text-3xl">{error.toString()}</div>
                  <div>{componentStack}</div>
                  <Button
                    onClick={() => {
                      resetError();
                    }}
                    error
                  >
                    Click here to reset!
                  </Button>
                </div>
              ) : (
                <Wrapper>
                  <div className="flex h-full w-full flex-col items-center justify-center gap-8">
                    <ExclamationTriangleIcon className="h-32 w-32 text-gray-500" />
                    <div className="flex flex-col gap-2 text-center text-finbox-dark">
                      <h1 className="text-4xl">Oops</h1>
                      Looks like something went wrong while loading the page.
                      Try refreshing the page.
                    </div>
                  </div>
                </Wrapper>
              );
            }}
          >
            {/* TODO: Remove unused routes */}
            <Routes>
              <Route path={"/"} element={<PageWithSidebar />}>
                <Route
                  path=""
                  element={
                    <ProtectedComponent
                      type={PERMISSIONS_TYPE.overview as PermissionTypes}
                      action="view"
                      showNotAllowed
                    >
                      <PrivateRoute>
                        <Overview />
                      </PrivateRoute>
                    </ProtectedComponent>
                  }
                />
                <Route
                  path="policies"
                  element={
                    <PrivateRoute>
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.policy as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <PolicyDashboard />
                      </ProtectedComponent>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="details/:policyId"
                  element={
                    <PrivateRoute>
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.policy as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <PolicyDetails />
                      </ProtectedComponent>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="details/analytics/:policyId"
                  element={
                    <PrivateRoute>
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.policy as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <PolicyAnalytics />
                      </ProtectedComponent>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="simulations"
                  element={
                    <PrivateRoute>
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.simulations as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <Simulations />
                      </ProtectedComponent>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="datasources/"
                  element={
                    <PrivateRoute>
                      <DataSource />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path=""
                    element={<Navigate replace to="/datasources/custom" />}
                  />
                  <Route
                    path="custom"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.dataSourcesCustom as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <CustomDataSources />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="finbox"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.apiStore as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <FinBoxDataSources />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="lookup"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.dataSourcesLookup as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <MasterLookupTable />
                      </ProtectedComponent>
                    }
                  />
                </Route>
                <Route
                  path="reports/"
                  element={
                    <PrivateRoute>
                      <Reports />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path=""
                    element={<Navigate replace to="/reports/outcomes" />}
                  />
                  <Route
                    path="outcomes"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.reportsOutcome as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <Decisions />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="simulations"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.simulations as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <Simulations />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="generated"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.reportsAll as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <GeneratedReports />
                      </ProtectedComponent>
                    }
                  />
                </Route>
                <Route
                  path="analytics"
                  element={
                    <PrivateRoute>
                      <Analytics />
                    </PrivateRoute>
                  }
                >
                  <Route path="" element={<Navigate replace to="template" />} />
                  <Route path="template" element={<Template />} />
                  <Route
                    path="template/:templateId/"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.analytics as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <Template />
                      </ProtectedComponent>
                    }
                  >
                    <Route
                      path="add-chart"
                      element={
                        <ProtectedComponent
                          type={PERMISSIONS_TYPE.analytics as PermissionTypes}
                          action={["create", "edit", "view"]}
                          // showNotAllowed
                        >
                          <AddChartModal />
                        </ProtectedComponent>
                      }
                    />
                  </Route>
                  <Route
                    path="policy-analytics"
                    element={<PolicyAnalyticsTemplate />}
                  />
                </Route>
                <Route
                  path="monitors/"
                  element={
                    <PrivateRoute>
                      <Monitor />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path=""
                    element={<Navigate replace to="/monitors/endpoint" />}
                  />
                  <Route
                    path="custom"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.monitorsCustom as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <CustomMonitors />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="endpoint"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.monitorsEndpoint as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <EndpointMonitors />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="alerts"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.monitorLogs as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <AlertLogs />
                      </ProtectedComponent>
                    }
                  />
                </Route>
                <Route
                  path="outcome/:userID"
                  element={
                    <PrivateRoute>
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.reportsEvaluation as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <OutcomeDetails />
                      </ProtectedComponent>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="outcome/:userID/:evaluationID"
                  element={
                    <PrivateRoute>
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.reportsEvaluation as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <OutcomeDetails />
                      </ProtectedComponent>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="endpoints"
                  element={
                    <ProtectedComponent
                      type={PERMISSIONS_TYPE.endpoints as PermissionTypes}
                      action="view"
                      showNotAllowed
                    >
                      <PrivateRoute>
                        <Endpoints />
                      </PrivateRoute>
                    </ProtectedComponent>
                  }
                >
                  <Route
                    path=":endpointId"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.endpoints as PermissionTypes}
                        action="view"
                        showNotAllowed
                      >
                        <EndpointDetails />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path=":endpointId/publish"
                    element={
                      <ProtectedComponent
                        type={PERMISSIONS_TYPE.endpoints as PermissionTypes}
                        action="publish"
                        showNotAllowed
                      >
                        <PublishPolicy />
                      </ProtectedComponent>
                    }
                  />
                </Route>
                <Route
                  path="/settings/"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path=""
                    element={
                      <Navigate replace to="/settings/general/account" />
                    }
                  />
                  <Route path="general" element={<Outlet />}>
                    <Route
                      path=""
                      element={
                        <Navigate replace to={"/settings/general/account"} />
                      }
                    />
                    <Route path="account" element={<SettingsGeneral />} />
                  </Route>

                  <Route
                    path="members"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.administrationMembers as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <Members />
                      </ProtectedComponent>
                    }
                  />
                  <Route
                    path="roles"
                    element={
                      <ProtectedComponent
                        type={
                          PERMISSIONS_TYPE.administrationRoles as PermissionTypes
                        }
                        action="view"
                        showNotAllowed
                      >
                        <Roles />
                      </ProtectedComponent>
                    }
                  />
                  <Route path="integration" element={<IntegrationWebhook />} />
                  <Route path="programs" element={<SettingsPrograms />} />
                  <Route path="enterprise-login" element={<PageNotFound />} />
                </Route>
                <Route path="settings/roles/update" element={<EditRoles />} />
              </Route>
              {/* ============ Pages without sidebar =========*/}
              <Route
                path="/create-policy/:policyID"
                element={
                  <PrivateRoute>
                    <ProtectedComponent
                      type={PERMISSIONS_TYPE.policy as PermissionTypes}
                      action="view"
                      showNotAllowed
                    >
                      <CreatePolicy />
                    </ProtectedComponent>
                  </PrivateRoute>
                }
              />
              <Route path="/error" element={<ErrorScreen />} />
              {WorkflowRouter}
              <Route
                path="/docs"
                element={
                  <PrivateRoute>
                    <Wrapper>
                      <Docs />
                    </Wrapper>
                  </PrivateRoute>
                }
              >
                {navigation
                  .flatMap((e) => e.links)
                  .map((n) => (
                    <Route
                      key={n.href}
                      path={n.href.replace(/\/docs[/]?/, "")}
                      element={<Md md={n.md} />}
                    />
                  ))}
                <Route path="data-dictionary" element={<DataDictionary />} />
              </Route>
              <Route
                path="/create-policy/:policyID"
                element={
                  <PrivateRoute>
                    <ProtectedComponent
                      type={PERMISSIONS_TYPE.policy as PermissionTypes}
                      action="view"
                      showNotAllowed
                    >
                      <CreatePolicy />
                    </ProtectedComponent>
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />}></Route>
              <Route path="/login/token/:token" element={<LoginWithToken />} />
              <Route path="/new-user/:resetToken" element={<SetPassword />} />
              <Route
                path="/reset-password/:resetToken"
                element={<SetForgotPassword />}
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/not-found" element={<PageNotFound />} />
              <Route
                path="*"
                element={<Navigate to="/not-found" replace />}
              />{" "}
            </Routes>
          </SentryErrorBoundary>
        </BrowserRouter>
      </Suspense>
      <Toaster></Toaster>
    </Providers>
  );
};

export default App;
