export const validateEmail = (email?: string) => {
  return (
    !!email &&
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(email).toLowerCase()
    )
  );
};

export const validatePassword = (p: string) => {
  let errors = [];
  if (p.length < 8) {
    errors.push("Password must be at least 8 characters");
  }
  if (p.search(/[a-z]/) < 0) {
    errors.push("Password must contain at least one lower case letter.");
  }
  if (p.search(/[A-Z]/) < 0) {
    errors.push("Password must contain at least one upper case letter.");
  }
  if (p.search(/[0-9]/) < 0) {
    errors.push("Password must contain at least one digit.");
  }
  if (p.search(/[^a-zA-Z0-9]/) < 0) {
    errors.push("Password must contain at least one special char");
  }
  return { valid: errors.length === 0, errors };
};

export function isValidURL(url: string): boolean {
  const urlRegex =
    /^(https?):\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/i;
  return urlRegex.test(url);
}
