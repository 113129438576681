import { getErrors } from "src/screens/workflow/queries";
import { FinBoxResponse } from "src/types";
import { getNetworkErrorText, notify } from "src/utils/utils";
import axios from "@axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { JsonNodeType, SaveJsonType, TransformBody } from "./JsonInput.types";

export const useTransformJson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: TransformBody) =>
      axios.v2.post<FinBoxResponse<JsonNodeType>, any>(`transformJSON`, body),
    onError(error) {
      notify({
        title: "Failed to add transform JSON",
        text: getNetworkErrorText(error),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getErrors());
    },
  });
};

export const useSaveJsonInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: SaveJsonType) =>
      axios.v2.post<FinBoxResponse<string>>(`saveJSON`, body),
    onError(error) {
      notify({
        title: "Failed to save JSON input",
        text: getNetworkErrorText(error),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getErrors());
    },
  });
};

export const useGetJsonInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (jsonInputId: string) =>
      axios.v2.get<FinBoxResponse<JsonNodeType>>(`viewJSON?id=${jsonInputId}`),
    onError(error) {
      notify({
        title: "Failed to fetch JSON input",
        text: getNetworkErrorText(error),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getErrors());
    },
  });
};
