import { Fragment } from "react";
import Button from "src/components/Button";
import Tooltip from "src/components/Tooltip";
import { classNames } from "src/utils/utils";
import { ReactComponent as ExternalLink } from "@assets/icons/link-external-02.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import { ReactComponent as CheckIcon } from "@assets/notificationIcons/check-circle-broken.svg";
import FileUpload from "@components/FileUpload";
import Input from "@components/Input";
import useDownloadInputSample from "../../hooks/useDownloadInputSample";
import useInputParamFileChange from "../../hooks/useInputParamFileChange";
import {
  InputError,
  InputParamDataEnum,
  WorkflowCustomInputType,
} from "./InputParameters.types";
import InputTypeSelection from "./InputTypeSelection";

function InputFormComp({
  errorList,
  inputParamList,
  appendInputParamList,
  inputTypeSelection,
  isNullableEnabled,
  isWorkflowEditable,
  handleMarkAllNullable,
}: {
  errorList: InputError;
  inputParamList: Array<WorkflowCustomInputType>;
  appendInputParamList: (list: Array<WorkflowCustomInputType>) => void;
  inputTypeSelection: (value: InputParamDataEnum, index: number) => void;
  isNullableEnabled: boolean;
  isWorkflowEditable: boolean;
  handleMarkAllNullable: () => void;
}) {
  const { onFileChange, fileUploadRef, file } =
    useInputParamFileChange(appendInputParamList);

  const { downloadSample } = useDownloadInputSample();

  return (
    <>
      <table>
        <thead className="flex mb-2 mt-3">
          <tr>
            <th className="pl-3 text-left font-normal text-[12px] w-[159px]">
              Variable
            </th>
            <th className="text-left font-normal text-[12px] w-[99px] pl-3">
              Type
            </th>
            <th
              className={classNames(
                "text-left font-normal text-[12px] pl-6",
                isWorkflowEditable ? "w-[138px]" : "w-[158px]"
              )}
            >
              <div className="flex flex-row gap-2">
                {isNullableEnabled && (
                  <Tooltip
                    bottom
                    left
                    contentClassName="flex items-center"
                    content={
                      <CheckIcon
                        className="w-4 h-4"
                        onClick={handleMarkAllNullable}
                      />
                    }
                    tooltipContent={
                      <p className="font-b2 text-neutral-black">
                        Mark all as nullable
                      </p>
                    }
                  />
                )}
                Value
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {inputParamList.map((iv, index) =>
            iv.operation === "delete" ? null : (
              <Fragment key={iv.id}>
                <tr
                  key={iv.id}
                  className="flex w-full h-[27px] mb-4 gap-3 items-center"
                >
                  <td className="pl-3 w-[159px]">
                    <Input
                      id={`${index}_name`}
                      disabled={!isWorkflowEditable}
                      placeholder="Enter variable"
                      className="h-[27px] mr-1"
                      defaultValue={iv.name}
                      inputClassName={
                        errorList[index]?.name && "!border-error-500"
                      }
                    />
                  </td>
                  <td className="w-[99px]">
                    <InputTypeSelection
                      inputTypeSelection={inputTypeSelection}
                      index={index}
                      value={iv.dataType}
                      disabled={
                        !isWorkflowEditable ||
                        (!iv?.id?.includes("dummy") &&
                          Boolean(iv?.schema) &&
                          iv?.dataType === InputParamDataEnum.Object)
                      }
                    />
                  </td>
                  <td
                    className={classNames(
                      isWorkflowEditable ? "w-[138px]" : "w-[170px]"
                    )}
                  >
                    <div className="flex gap-2">
                      {isNullableEnabled && (
                        <input
                          className="mt-[6px]"
                          id={`${index}_nullable`}
                          type="checkbox"
                          checked={iv.isNullable}
                          disabled={!isWorkflowEditable}
                        />
                      )}
                      {iv.dataType === InputParamDataEnum.Object && (
                        <Button
                          id={`${index}_openJson`}
                          variant="outline"
                          className="ml-auto w-full justify-center "
                        >
                          {iv?.schema ? "View" : "Configure"}
                          <span className="pointer-events-none flex items-center">
                            <ExternalLink className="[&>path]:stroke-neutral-500 w-4 h-4" />
                          </span>
                        </Button>
                      )}
                      {iv.dataType !== InputParamDataEnum.Object && (
                        <Input
                          id={`${index}_defaultInput`}
                          disabled={!isWorkflowEditable}
                          defaultValue={iv.defaultInput ?? ""}
                          placeholder="Enter value"
                          className="h-[27px] mr-1"
                          inputClassName={
                            errorList[index]?.defaultInput
                              ? "!border-error-500"
                              : ""
                          }
                        />
                      )}
                    </div>
                  </td>
                  {isWorkflowEditable && (
                    <td className="pr-3">
                      <TrashIcon
                        id={`${index}_delete`}
                        className="h-4 w-4 cursor-pointer [&:hover>path]:stroke-error-500"
                      />
                    </td>
                  )}
                </tr>
                <tr>
                  {errorList[index] && (
                    <td
                      className="px-3 text-error-500 font-b2 flex items-center"
                      colSpan={4}
                    >
                      {Object.keys(errorList[index]).map(
                        (errKey, errKeyIndex) =>
                          errKeyIndex === 0
                            ? errorList[index]?.[errKey]
                            : `, ${errorList[index]?.[errKey]}`
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            )
          )}
        </tbody>
      </table>
      {isWorkflowEditable && (
        <div
          id={"addInput"}
          className="font-b2-medium cursor-pointer px-3 mt-1 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1 mb-1"
        >
          <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
          Add Parameters
        </div>
      )}
      <div className="mt-auto w-full px-4 font-b2 mb-4">
        <FileUpload
          ref={fileUploadRef}
          file={file}
          setFile={onFileChange}
          placeholder="Upload a CSV or JSON file"
        />
        Download the{" "}
        <button
          onClick={() => downloadSample("csv")}
          className="text-primary-900 font-medium"
        >
          sample CSV file
        </button>{" "}
        or the{" "}
        <button
          onClick={() => downloadSample("json")}
          className="text-primary-900 font-medium"
        >
          sample JSON file
        </button>
        .
      </div>
    </>
  );
}

export const InputForm = InputFormComp;
