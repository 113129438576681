import { useState } from "react";
import Badge from "src/components/Badge";
import ConfirmationModal from "src/components/ConfirmationModal";
import Header from "src/components/Header";
import Input from "src/components/Input";
import Shimmer from "src/components/Shimmer";
import Table from "src/components/Table";
import { analyticsInstance } from "src/config/event-analytics";
import { WebhookActions } from "src/constants/EventAnalytics";
import useBreadcrumbs from "src/context/BreadcrumbsContext";
import { notify } from "src/utils/utils";
import { ReactComponent as PencilIcon } from "@assets/icons/editPencilHollow.svg";
import { ReactComponent as EyeIcon } from "@assets/icons/eye.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import { EyeSlashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import { WEBHOOK_EVENTS_LIST } from "./constants";
import {
  getWebhookChannelDetails,
  useDeleteHookEventChannel,
  useWebhookChannelDetails,
} from "./queries";
import NoWebhookEmptyState from "./subComponents/NoWebhookEmptyState";
import WebhookEditModal from "./subComponents/WebhookEditModal";
import { EventTypes } from "./types";

const IntegrationWebhook = () => {
  useBreadcrumbs([
    {
      name: "Integration",
      link: "/settings/integration",
    },
  ]);
  const [isWebhookModal, setIsWebhookmodal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isViewPassword, setIsViewPassword] = useState<Record<string, boolean>>(
    {}
  );

  const {
    data: webhookData,
    isPending,
    isSuccess,
  } = useWebhookChannelDetails();
  const deleteHook = useDeleteHookEventChannel();
  const qc = useQueryClient();

  const handleAddOpenModal = () => {
    setIsWebhookmodal(true);
    analyticsInstance.triggerAnalytics(WebhookActions.WEBHOOKS_ADD);
  };
  const handleOpenModal = () => {
    setIsWebhookmodal(true);
    analyticsInstance.triggerAnalytics(WebhookActions.EDIT_WEBHOOK);
  };

  const handleCloseModal = () => {
    setIsWebhookmodal(false);
  };

  const handleConfirmDelete = () => {
    if (webhookData && webhookData.channelID) {
      deleteHook.mutate(
        { channelId: webhookData.channelID },
        {
          onSuccess: (e) => {
            notify({
              title: "Deleted webhook",
              text: e.data.data,
              type: "success",
            });
            qc.invalidateQueries(getWebhookChannelDetails());
          },
        }
      );
      analyticsInstance.triggerAnalytics(WebhookActions.DELETE_WEBHOOK);
    }
  };
  const togglePasswordVisibility = (field: string) => {
    setIsViewPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleWebhookDelete = () => {
    setShowDelete(true);
  };

  return (
    <>
      <Header className="w-full border-b-0">
        <div>
          <div className="flex justify-between">
            <div className="flex justify-between w-full items-center">
              <div className="my-5 mx-2 mb-3">
                <Header.PageHeader>Webhook</Header.PageHeader>
                <Header.Description>
                  View and manage your webhook integrations
                </Header.Description>
              </div>
              {isSuccess && webhookData.channelType === 1 && (
                <div className="flex gap-3">
                  <div
                    className="flex cursor-pointer border border-neutral-100 rounded w-8 h-8 items-center justify-center"
                    onClick={handleWebhookDelete}
                  >
                    <TrashIcon className="[&>path]:stroke-neutral-black h-4 w-4" />
                  </div>

                  <div
                    className="flex cursor-pointer border border-neutral-100 rounded w-8 h-8 items-center justify-center"
                    onClick={handleOpenModal}
                  >
                    <PencilIcon className="[&>path]:stroke-neutral-black h-4 w-4" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Header>
      {(deleteHook.isPending || isPending) && (
        <Shimmer w="95%" h="450px" className="my-10 mx-8" />
      )}

      {isSuccess &&
      webhookData.channelType === 1 &&
      webhookData.channelConfig ? (
        <div className="mx-8 flex flex-col gap-6">
          <div className="flex flex-col gap-1 w-full">
            <label className="font-b2-medium text-neutral-black">URL</label>
            <Input
              disabled
              value={webhookData?.channelConfig?.url ?? ""}
              className="w-[522px]"
            />
          </div>
          {Object.entries(webhookData.channelConfig.headers).length > 0 && (
            <div className="flex flex-col gap-1">
              <label className="flex gap-2 font-b2-medium text-neutral-black items-center">
                Header{" "}
                <Badge variant="secondary">
                  {Object.entries(webhookData.channelConfig.headers)?.length}{" "}
                  Key-Value Pair(s)
                </Badge>
              </label>
              <p className="font-b2 text-neutral-500 mb-3">
                Specify the request header parameters as key value pairs.
              </p>
              <div className="flex">
                <label className="font-b2-medium text-neutral-black flex mb-2 w-[172px]">
                  Key
                </label>
                <label className="font-b2-medium text-neutral-black mb-2 w-[351px]">
                  Value
                </label>
              </div>
              <div className="flex flex-col gap-3">
                {Object.entries(webhookData.channelConfig.headers).map(
                  ([key, value]) => (
                    <div className="flex items-center gap-4" key={key}>
                      <label className="font-b2 text-neutral-black w-[156px]">
                        {key}
                      </label>
                      <div className="relative w-[351px]">
                        <input
                          type={isViewPassword[key] ? "text" : "password"}
                          value={value}
                          disabled
                          className="h-7 bg-white border-neutral-100 focus:border-blue-500 focus:ring-0 font-b2 disabled:cursor-not-allowed placeholder-neutral-500 block w-full appearance-none rounded-md border px-4 py-2.5 focus:outline-none focus:ring-primary-900 font-b1"
                        />
                        <div
                          className="h-4 w-4 absolute right-2.5 top-1.5 text-neutral-500 hover:text-neutral-black cursor-pointer"
                          onClick={() => togglePasswordVisibility(key)}
                        >
                          {isViewPassword[key] ? <EyeSlashIcon /> : <EyeIcon />}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          <div>
            <label className="font-b2-medium text-neutral-black">
              Subscribed Events
            </label>
            <div className="max-w-full h-full w-full z-0 mt-3 rounded bg-white pb-8 overflow-auto">
              <Table
                data={webhookData?.events ?? []}
                className="sm:overflow-x-visible w-full"
                isLoading={false}
                headers={["Event", "Description"]}
              >
                {(ev) => (
                  <>
                    {ev.enabled && (
                      <tr>
                        <td className="font-b2-medium w-2/6 px-6">
                          {WEBHOOK_EVENTS_LIST[ev.eventType as EventTypes].name}
                        </td>
                        <td className="w-4/6 font-b1 text-neutral-black py-4 pr-2">
                          {
                            WEBHOOK_EVENTS_LIST[ev.eventType as EventTypes]
                              .description
                          }
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </Table>
            </div>
          </div>
        </div>
      ) : (
        isSuccess && (
          <NoWebhookEmptyState handleOpenModal={handleAddOpenModal} />
        )
      )}
      {isSuccess && isWebhookModal && (
        <WebhookEditModal
          channelData={webhookData?.channelConfig}
          channelId={webhookData?.channelID}
          isAddWebhook={webhookData.events ? false : true}
          handleCloseModal={handleCloseModal}
        />
      )}
      <ConfirmationModal
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
        destructive
        action={() => handleConfirmDelete()}
        title="Confirm deletion"
      >
        Are you sure you want to delete this Webhook and corresponding events
        associated?
      </ConfirmationModal>
    </>
  );
};

export default IntegrationWebhook;
