import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import Input from "src/components/Input";
import Toggle from "src/components/Toggle";
import { analyticsInstance } from "src/config/event-analytics";
import { WebhookActions } from "src/constants/EventAnalytics";
import { classNames, notify } from "src/utils/utils";
import { isValidURL } from "src/utils/validate";
import { ReactComponent as MinusIcon } from "@assets/icons/minus.svg";
import { useQueryClient } from "@tanstack/react-query";
import { WEBHOOK_EVENTS_LIST } from "../constants";
import {
  getWebhookChannelDetails,
  useAddHookEventChannel,
  useGetClientAllowedEvents,
  useUpdateHookEventChannel,
} from "../queries";
import { WebhookFormValues } from "../types";
import { transformDataWebhook } from "../utils";

const WebhookEditModal = ({
  isAddWebhook,
  handleCloseModal,
  channelData,
  channelId,
}: {
  isAddWebhook: boolean;
  handleCloseModal: () => void;
  channelData: any;
  channelId: string;
}) => {
  const { data: eventsList, isSuccess } = useGetClientAllowedEvents();
  const addHook = useAddHookEventChannel();
  const updateHook = useUpdateHookEventChannel();
  const qc = useQueryClient();

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<WebhookFormValues>({
    defaultValues: {
      url: channelData?.url ?? "",
      parameters: Object.entries(channelData?.headers || {}).map(
        ([key, value]) => ({
          key,
          value: value as string,
        })
      ),
      events:
        eventsList?.map((event) => ({
          eventID: event.eventID,
          enabled: !!event.enabled,
          eventType: event.eventType,
        })) || [],
    },
  });

  const {
    fields: parameterFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "parameters",
  });

  const { fields: eventFields } = useFieldArray({
    control,
    name: "events",
  });

  useEffect(() => {
    if (isSuccess && eventsList) {
      setValue(
        "events",
        eventsList.map((event) => ({
          eventID: event.eventID,
          enabled: !!event.enabled,
          eventType: event.eventType,
        }))
      );
    }
  }, [isSuccess, eventsList, setValue]);

  const isAnyEventEnabled = watch("events").some((event) => event.enabled);

  const handleAddParameter = () => {
    append({ key: "", value: "" });
  };

  const onSubmit = (data: WebhookFormValues) => {
    const channelType = "1";
    const transformedData = transformDataWebhook({
      url: data.url,
      parameters: data.parameters,
      eventToggles: data.events,
      channelType,
    });

    if (isAddWebhook) {
      addHook.mutate(
        { data: transformedData },
        {
          onSuccess: (e) => {
            notify({
              title: "Added webhook",
              text: e.data.data,
              type: "success",
            });
            qc.invalidateQueries(getWebhookChannelDetails());
            handleCloseModal();
          },
        }
      );
      analyticsInstance.triggerAnalytics(WebhookActions.CONFIGURE_WEBHOOK_SAVE);
    } else {
      updateHook.mutate(
        { data: transformedData, channelId },
        {
          onSuccess: (e) => {
            notify({
              title: "Updated webhook",
              text: e.data.data,
              type: "success",
            });
            qc.invalidateQueries(getWebhookChannelDetails());
            handleCloseModal();
          },
        }
      );
      analyticsInstance.triggerAnalytics(WebhookActions.EDIT_WEBHOOK_SAVE);
    }
  };

  return (
    <Modal open={true} onClose={handleCloseModal}>
      <Modal.Panel size="lg">
        <Modal.Header>Configure Webhook</Modal.Header>
        <Modal.Body className="flex flex-col gap-6">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <div>
              <label className="font-b2-medium text-neutral-black">URL</label>
              <Input
                {...register("url", {
                  required: "URL is required",
                  validate: (value) => isValidURL(value) || "*enter valid URL",
                })}
                placeholder="https://wwww.google.com"
                error={errors.url?.message}
              />
            </div>
            <div>
              <label className="font-b2-medium text-neutral-black">
                Headers
              </label>
              <p className="font-b2 text-neutral-500 mb-2">
                Specify the request header parameters as key-value pairs.
              </p>
              {parameterFields.length > 0 && (
                <div className="flex">
                  <label className="font-b2-medium text-neutral-black flex mb-2 w-[48%]">
                    Key
                  </label>
                  <label className="font-b2-medium text-neutral-black ml-0.5 mb-2">
                    Value
                  </label>
                </div>
              )}
              <div className="flex flex-col gap-1">
                {parameterFields.map((param, index) => (
                  <div key={param.id} className="flex gap-2 items-center">
                    <Input
                      {...register(`parameters.${index}.key`, {
                        required: "*key is required",
                        maxLength: {
                          value: 28,
                          message: "Max length 28 characters",
                        },
                        validate: {
                          validName: (v) =>
                            /^[A-Za-z0-9_-]+$/.test(v) ||
                            "*enter valid key (A-Z, a-z, 0-9, _, -)",
                        },
                      })}
                      placeholder="Key"
                      className="h-[48px] w-[47%]"
                      error={errors.parameters?.[index]?.key?.message}
                    />
                    <Input
                      {...register(`parameters.${index}.value`, {
                        required: "*value is required",
                        maxLength: {
                          value: 28,
                          message: "Max length 28 characters",
                        },
                      })}
                      className="h-[48px] w-[47%]"
                      placeholder="Value"
                      error={errors.parameters?.[index]?.value?.message}
                    />
                    <MinusIcon
                      className={classNames(
                        "w-4 h-4 stroke-neutral-500 -mt-5 cursor-pointer"
                      )}
                      onClick={() => remove(index)}
                    />
                  </div>
                ))}
                <div className="flex">
                  <button
                    type="button"
                    onClick={handleAddParameter}
                    className="font-b2-medium text-neutral-black hover:text-primary-900"
                  >
                    + Add Parameter
                  </button>
                </div>
              </div>
            </div>
            <div>
              <label className="flex font-b2-medium mb-3">Events</label>
              <div className="flex flex-col gap-4">
                {eventFields?.map((event, index) => {
                  return (
                    <div key={event.id} className="flex flex-col">
                      <div className="flex gap-2 ">
                        <Toggle
                          value={watch(`events.${index}.enabled`)}
                          onChange={() =>
                            setValue(
                              `events.${index}.enabled`,
                              !watch(`events.${index}.enabled`)
                            )
                          }
                        />
                        <span className="font-b2-medium">
                          {WEBHOOK_EVENTS_LIST[event.eventType]?.name}
                        </span>
                      </div>
                      <span className="font-b2 text-neutral-500 ml-11">
                        {WEBHOOK_EVENTS_LIST[event.eventType]?.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <Modal.Footer className="flex justify-end items-center w-full gap-2 !pb-0">
              <Button variant="outline" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" disabled={!isAnyEventEnabled}>
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal.Panel>
    </Modal>
  );
};

export default WebhookEditModal;
