import { getConfigureWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { useQuery } from "@tanstack/react-query";

// gives list of keywords according to dependents for nodeId
export default function useKeywordsPredictorsForConfigureModal(
  workflowId?: string,
  nodeId?: string
) {
  const workflowKeywords = useQuery(
    getConfigureWorkflowKeywordsQuery(workflowId)
  );
  const deps = workflowKeywords?.data?.dependents[nodeId || ""];
  const keywords = {
    policies: {} as Record<string, string[]>,
    workflows: {} as Record<string, string[]>,
    sources: {} as Record<string, string[]>,
    customSources: {} as Record<string, string[]>,
    modelSet: {} as Record<string, string[]>,
    ruleSet: {} as Record<string, string[]>,
    lookupsSource: {} as Record<string, string[]>,
    input: {} as Record<string, string[]>,
    args: {} as Record<string, string[]>,
  };
  if (!workflowId || !nodeId) return keywords;
  if (!workflowKeywords.isSuccess) return keywords;
  if (workflowKeywords.data.input.list.length > 0) {
    keywords.input = {
      input: workflowKeywords.data.input.list,
    };
  }
  if (workflowKeywords.data?.args?.list?.length > 0) {
    keywords.args = {
      args: workflowKeywords.data.args.list,
    };
  }
  if (!deps) return keywords;
  if (deps.pluginSources) {
    deps.pluginSources.forEach((source) => {
      keywords.sources[source] =
        workflowKeywords.data.predictors.pluginSources[source]?.list;
    });
  }
  if (deps.customSources) {
    deps.customSources.forEach((source) => {
      keywords.customSources[source] =
        workflowKeywords.data.predictors.customSources[source]?.list;
    });
  }
  if (deps.lookupsSource) {
    deps.lookupsSource.forEach((source) => {
      keywords.lookupsSource[source] =
        workflowKeywords.data.predictors.lookupsSource[source]?.list;
    });
  }
  if (deps.modelSet) {
    deps.modelSet.forEach((source) => {
      keywords.modelSet[source] =
        workflowKeywords.data.predictors.modelSet[source]?.list;
    });
  }
  if (deps.ruleSet) {
    deps.ruleSet.forEach((source) => {
      keywords.ruleSet[source] =
        workflowKeywords.data.predictors.ruleSet[source]?.list;
    });
  }

  if (deps.policies)
    deps.policies.forEach((policy) => {
      keywords.policies[policy] =
        workflowKeywords.data.predictors.policies[policy]?.list;
    });

  if (deps.workflows)
    deps.workflows.forEach((workflow) => {
      keywords.workflows[workflow] =
        workflowKeywords.data.predictors.workflows[workflow]?.list;
    });
  return keywords;
}

export const getPredictorsType = (parentPredictors: string) => {
  switch (true) {
    case parentPredictors.startsWith("sources"):
      return 1;
    case parentPredictors.startsWith("input"):
      return 2;
    case parentPredictors.startsWith("customSources"):
      return 3;
    case parentPredictors.startsWith("lookupsSource"):
      return 4;
    case parentPredictors.startsWith("ruleSet"):
      return 5;
    case parentPredictors.startsWith("modelSet"):
      return 6;
    case parentPredictors.startsWith("policies"):
      return 7;
    case parentPredictors.startsWith("workflows"):
      return 8;
    case parentPredictors.startsWith("args"):
      return 9;
    default:
      return -1;
  }
};
export const getPredictorsName = (type: number) => {
  switch (type) {
    case 1:
      return "sources";
    case 2:
      return "input";
    case 3:
      return "customSources";
    case 4:
      return "lookupsSource";
    case 5:
      return "ruleSet";
    case 6:
      return "modelSet";
    case 7:
      return "policies";
    case 8:
      return "workflows";
    case 9:
      return "args";
    default:
      return "";
  }
};
