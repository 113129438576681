import clsx from "clsx";
import { ComponentProps } from "react";
import { ReactComponent as BranchIcon } from "@assets/icons/shuffle-01.svg";
import { ReactComponent as TableIcon } from "@assets/icons/table.svg";
import { ReactComponent as ExpressionIcon } from "@assets/icons/workflow/calculator.svg";
import { ReactComponent as ApprovedIcon } from "@assets/icons/workflow/check-circle.svg";
import { ReactComponent as SourceIcon } from "@assets/icons/workflow/database-01.svg";
import { ReactComponent as CantIcon } from "@assets/icons/workflow/help-circle.svg";
import { ReactComponent as PolicyIcon } from "@assets/icons/workflow/policy.svg";
import { ReactComponent as RuleGroupIcon } from "@assets/icons/workflow/rule.svg";
import { ReactComponent as CustomIcon } from "@assets/icons/workflow/tool-01.svg";
import { ReactComponent as RejectIcon } from "@assets/icons/workflow/x-circle.svg";
import {
  BRANCH_NODE_TYPE,
  DECISION_TABLE_NODE_TYPE,
  END_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  POLICY_NODE_TYPE,
  RULE_GROUP_NODE_TYPE,
  RULE_SET_NODE_TYPE,
  SOURCE_NODE_TYPE,
  WORKFLOW_NODE_TYPE,
} from "@screens/workflow/config";
import { ModelSetItem } from "@screens/workflow/studio/components/ModelSet/types";
import {
  InputParamOperation,
  WorkflowCustomInputType,
} from "./studio/components/InputParameters/InputParameters.types";

export const getIcon = (
  type?: string | undefined,
  label?: string,
  props?: ComponentProps<"svg">
) => {
  const svgProps = {
    ...props,
    className: clsx("w-4 h-4", props?.className),
  };
  switch (type) {
    case POLICY_NODE_TYPE:
      return <PolicyIcon {...svgProps} />;
    case WORKFLOW_NODE_TYPE:
      return <PolicyIcon {...svgProps} />;
    case RULE_GROUP_NODE_TYPE:
      return <RuleGroupIcon {...svgProps} />;
    case BRANCH_NODE_TYPE:
      return <BranchIcon {...svgProps} />;
    case MODEL_NODE_TYPE:
      return <ExpressionIcon {...svgProps} />;
    case MODEL_SET_NODE_TYPE:
      return <ExpressionIcon {...svgProps} />;
    case RULE_SET_NODE_TYPE:
      return <RuleGroupIcon {...svgProps} />;
    case DECISION_TABLE_NODE_TYPE:
      return <TableIcon {...svgProps} />;
    case SOURCE_NODE_TYPE:
      return <SourceIcon {...svgProps} />;

    case END_NODE_TYPE:
      switch (label?.toLowerCase()) {
        case "approved":
        case "success":
        case "approve":
        case "pass":
          return (
            <ApprovedIcon
              {...props}
              className={clsx(
                "stroke-success-500 fill-success-100 [&>path]:stroke-success-500 [&>path]:fill-success-100 w-4 h-4",
                props?.className
              )}
            />
          );
        case "reject":
        case "rejected":
        case "fail":
          return (
            <RejectIcon
              {...props}
              className={clsx(
                "stroke-error-500 fill-error-200 [&>path]:stroke-error-500 [&>path]:fill-error-200 w-4 h-4",
                props?.className
              )}
            />
          );
        case "cant_decide":
        case "can't decide":
          return (
            <CantIcon
              {...props}
              className={clsx(
                "stroke-warning-500 fill-warning-100 [&>path]:stroke-warning-100 [&>path]:fill-warning-100 w-4 h-4",
                props?.className
              )}
            />
          );
      }
      return (
        <CustomIcon
          {...props}
          className={clsx("stroke-primary-600 w-4 h-4", props?.className)}
        />
      );
  }
  return null;
};

export const getModelSetItemIcon = (
  type: ModelSetItem["type"],
  svgProps?: ComponentProps<"svg">
) => {
  switch (type) {
    case "expression":
      return <ExpressionIcon {...svgProps} />;
    case "decisionTable":
      return (
        <TableIcon
          {...svgProps}
          className={clsx(
            "w-4 h-4 [&_path]:stroke-[#C02B8D] [&_path]:fill-transparent",
            svgProps?.className
          )}
        />
      );
  }
};

export function extractNameAndVersion(input: string) {
  const regex = /(.+)\s+v([\d.]+)/;
  const match = input.match(regex);

  if (match) {
    return {
      name: match[1].trim(),
      version: match[2].trim(),
    };
  }
  return {
    name: "",
    version: "",
  };
}

export const getOperationType = (item: WorkflowCustomInputType) => {
  if (item?.id.includes("dummy")) {
    return InputParamOperation.ADD;
  } else if (item.operation === "delete") {
    return InputParamOperation.DELETE;
  } else {
    return InputParamOperation.EDIT;
  }
};
