export const WEBHOOK_EVENTS_LIST: Record<
  number,
  { name: string; description: string }
> = {
  1: {
    name: "Policy deployment",
    description:
      "Get notified when a policy is deployed at an endpoint. The event will contain deployment information for you to keep track of what version of policy is deployed and in which mode.",
  },
  2: {
    name: "Policy archived",
    description:
      "Get notified when a user archives a policy. This event will contain the name of the user and the version that was archived.",
  },
  3: {
    name: "Policy approved by checker",
    description:
      "Get notified when a checker marks a policy as ready to be deployed. The event will contain the checkers information along with schema of the workflow.",
  },
  4: {
    name: "Evaluation triggered",
    description:
      "Get notified when an evaluation is triggered, including details like the final decision and workflow execution insights.",
  },
};
